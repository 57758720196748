import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface MultipleSelectionDropDownProps {
  label: string
  data: string[]
  disable: boolean
  selectionState: any
  handleSelectionChange: (item: any, event?: any) => void
  hasValidationError?: boolean
  errorMessage?: string
  customSelectionItemName?: string
  customSelectionData?: string[]
  customSelectedMessage?: string
  selectAll?: boolean
  separator?: string
  displayProperties?: string[]
  maxWidth?: string
  minWidth?: string
  labelSx?: any
  displayFlexOverrideWidth?: string
}

const useStyles = makeStyles((displayFLex) => ({
  formControl: {
    width: displayFLex ? '' : '300px',
    display: displayFLex ? 'flex' : 'inline-flex',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))

const MenuProps: any = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
  labelAdjustment: {
    transform: 'translate(0px, 20px) scale(1) !important',
  },
}

const MultipleSelectionDropDown: React.FC<MultipleSelectionDropDownProps> = ({
  label,
  data,
  disable,
  selectionState,
  handleSelectionChange,
  hasValidationError,
  errorMessage,
  customSelectionItemName,
  customSelectionData,
  customSelectedMessage,
  selectAll,
  maxWidth,
  minWidth,
  separator,
  displayProperties,
  labelSx,
  displayFlexOverrideWidth,
}: MultipleSelectionDropDownProps) => {
  const classes = useStyles(displayFlexOverrideWidth)
  const EnableCustomSelection = !!customSelectionData
  const isAllItemsSelected =
    data.length > 0 && selectionState.length === data.length

  const isCustomSelectionSelected = () => {
    if (selectionState.length === 0) return false

    const result = selectionState.every((str: string) =>
      customSelectionData?.includes(str)
    )

    return result && selectionState.length === customSelectionData?.length
  }

  const handleChange = (event: any) => {
    const { value } = event.target

    if (value[value.length - 1] === 'all') {
      handleSelectionChange(
        selectionState.length === data.length ? [] : data,
        'AllSelect'
      )
      return
    }

    if (value[value.length - 1] === 'custom_selection') {
      handleSelectionChange(
        selectionState.length === customSelectionData?.length
          ? []
          : customSelectionData,
        'CustomSelect'
      )
      return
    }

    handleSelectionChange(value, 'CustomSelect')
  }

  const getItemSelectedLabel = (): string | null => {
    if (isAllItemsSelected) return ' (All items have been selected)'

    if (isCustomSelectionSelected()) return `${customSelectedMessage}`

    if (selectionState.length === 0) return null
    if (selectionState.length > 1)
      return ` (${selectionState.length} item selected)`
    if (selectionState.length === 1) return ' (1 item selected)'

    return null
  }

  return (
    <FormControl disabled={disable} className={classes.formControl}>
      <InputLabel
        id="mutiple-select-label"
        style={{
          width: 'max-content',
        }}
        sx={labelSx}
      >
        {label}
        {getItemSelectedLabel()}
      </InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        fullWidth
        value={selectionState}
        onChange={handleChange}
        renderValue={(selected: any) => selected.join(', ')}
        MenuProps={MenuProps}
        style={{
          minWidth: minWidth ?? (displayFlexOverrideWidth ? '200px' : ''),
          maxWidth: maxWidth ?? (displayFlexOverrideWidth ? '200px' : ''),
        }}
        disabled={disable}
        error={hasValidationError}
      >
        {selectAll && (
          <MenuItem
            value="all"
            classes={{
              root: isAllItemsSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox checked={isAllItemsSelected} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
        )}

        {EnableCustomSelection && (
          <MenuItem
            value="custom_selection"
            classes={{
              root: isAllItemsSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox checked={isCustomSelectionSelected()} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary={customSelectionItemName}
            />
          </MenuItem>
        )}

        {data.map((item: any) => (
          <MenuItem key={item} value={item}>
            <ListItemIcon>
              <Checkbox checked={selectionState.indexOf(item) > -1} />
            </ListItemIcon>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
      {hasValidationError && (
        <FormHelperText error={hasValidationError}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

MultipleSelectionDropDown.defaultProps = {
  hasValidationError: undefined,
  errorMessage: undefined,
  customSelectionItemName: undefined,
  customSelectionData: undefined,
  customSelectedMessage: undefined,
  selectAll: true,
  separator: undefined,
  displayProperties: undefined,
  labelSx: {},
  displayFlexOverrideWidth: undefined,
}

export default MultipleSelectionDropDown
