/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  // detectSubjectType,
} from '@casl/ability'
// import { UserPermissions } from 'src/context/AuthenticationContext'

import { Permissions } from 'src/graphql/models/permissions'

type Actions =
  | '001'
  | '001.001'
  | '001.000.000'
  | '001.000.001'
  | '001.001.000'
  | '001.001.001'
  | '001.001.002'
  | '001.001.003'
  | '001.001.004'
  | '001.001.005'
  | '001.002'
  | '001.002.000'
  | '001.002.001'
  | '001.002.002'
  | '001.002.003'
  | '001.002.004'
  | '001.002.005'
  | '001.002.006'
  | '001.002.007'
  | '001.002.008'
  | '001.002.009'
  | '001.002.010'
  | '001.002.011'
  | '001.002.012'
  | '001.002.013'
  | '001.003'
  | '001.003.000'
  | '001.003.001'
  | '001.004'
  | '001.004.000'
  | '001.004.001'

type Subjects = 'any'

export type AppAbility = Ability<[Actions, Subjects]>

// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
export const AppAbility = Ability as AbilityClass<AppAbility>

export default function defineRulesFor(
  role: string,
  permissionValues: Permissions[]
) {
  const { can, rules, cannot } = new AbilityBuilder<AppAbility>(AppAbility)
  permissionValues.forEach((v) => {
    if (v.value) {
      can(v.code as Actions, 'any')
    } else {
      cannot(v.code as Actions, 'any')
    }
  })
  return rules
}

export function buildAbilityFor(
  role: string,
  permissionValues: Permissions[]
): AppAbility {
  return new AppAbility(defineRulesFor(role, permissionValues))
}
