import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  GET_APPROVED_AGENCIES,
  GET_AGENCIES,
} from 'src/graphql/operations/queries/clientProfiles'

import TransferList from 'src/components/TransferList'

import { Box, Skeleton } from '@mui/material'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import {
  useAddApprovedAgencies,
  useRemoveApprovedAgencies,
} from 'src/graphql/operations/mutations/clientProfiles'

import { cache } from 'src/apollo-cache'

interface AgencyAssociationsProps {
  clientProfileId: number
}

const removeDuplicateArrayById = (
  listDuplicated: any[] = [],
  listCompare: any[] = []
) => {
  if (listCompare.length > 0) {
    const listCompareMapper = listCompare.reduce((acc, item) => {
      const ac = { ...acc }
      if (item && item.id) {
        ac[item.id] = item.id
      }
      return ac
    }, {})
    const listDuplacatedRemoved = listDuplicated.reduce((acc, item) => {
      const ac = [...acc]
      if (!listCompareMapper[item.id]) {
        ac.push(item)
      }
      return ac
    }, [])
    return listDuplacatedRemoved
  }
  return listDuplicated
}

const AgencyAssociations = ({ clientProfileId }: AgencyAssociationsProps) => {
  const ability = useContext(AbilityContext)

  const { enqueueSnackbar } = useSnackbar()

  const { loading } = useQuery<any>(GET_AGENCIES)

  const { data: getApprovedAgencies, loading: approvedAgenciesLoading } =
    useQuery<any>(GET_APPROVED_AGENCIES, {
      fetchPolicy: 'no-cache',

      variables: {
        clientId: Number(clientProfileId),
      },
    })

  const handleAddApprovedAgenciesCompleted = (addedData: any) => {
    if (addedData) {
      enqueueSnackbar('Agency selection updated successfully', {
        variant: 'success',
      })
    }
  }

  const handleRemoveApprovedAgenciesCompleted = (removedData: any) => {
    if (removedData) {
      enqueueSnackbar('Agency selection updated successfully', {
        variant: 'success',
      })
    }
  }

  const { addApprovedAgencies, loading: loadingApprove } =
    useAddApprovedAgencies(handleAddApprovedAgenciesCompleted)

  const { removeApprovedAgencies, loading: loadingRemove } =
    useRemoveApprovedAgencies(handleRemoveApprovedAgenciesCompleted)

  const handleSendLeftColumn = (leftData: any[]) => {
    if (leftData.length) {
      const associatedClientId = leftData.map((item) => item.id)
      removeApprovedAgencies({
        variables: {
          approvedAgencies: {
            clientId: Number(clientProfileId),
            agenciesId: associatedClientId,
          },
        },
        refetchQueries: ['GetApprovedAgencies'],
      })
    }
  }

  const handleonSendRightColumn = (rightData: any[]) => {
    if (rightData.length) {
      const associatedClientId = rightData.map((item) => item.id)
      addApprovedAgencies({
        variables: {
          approvedAgencies: {
            clientId: Number(clientProfileId),
            agenciesId: associatedClientId,
          },
        },
        refetchQueries: ['GetApprovedAgencies'],
      })
    }
  }

  let clientList: any[] | null = null

  if (!approvedAgenciesLoading && !loading) {
    const agencies = cache.readQuery<any>({
      query: GET_AGENCIES,
    }).getAgencies.businesses

    const clientListFiltered = agencies.filter(
      (agency: any) => agency.status === 'Approved'
    )

    const clientListNormalize = removeDuplicateArrayById(
      clientListFiltered,
      getApprovedAgencies?.getApprovedAgencies
    )
    clientList = clientListNormalize
  }

  if (!loading && !approvedAgenciesLoading) {
    return (
      <TransferList
        loading={
          loading || approvedAgenciesLoading || loadingApprove || loadingRemove
        }
        leftData={clientList || []}
        rightData={getApprovedAgencies?.getApprovedAgencies || []}
        showAllButtons={false}
        onSendLeftColumn={handleSendLeftColumn}
        onSendRightColumn={handleonSendRightColumn}
        readonly={
          !ability.can(
            PermissionCodeAccess.CLIENT_NONSELLER_STATUS_PERMISSION,
            'any'
          )
        }
      />
    )
  }
  return (
    <Box>
      <Skeleton variant="rectangular" width={400} height={300} />
    </Box>
  )
}

export default AgencyAssociations
