import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Theme } from '@mui/material'

import { GetClient } from 'src/graphql/models/clientProfiles'
import Comment from 'src/components/Comment'
import UserList from 'src/components/User/UserList'
import { PermissionCodeAccess, TENANT_TYPE } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import ClientProfileForm from './ClientProfileForm'
// import ClientComments from './ClientComments'
import ClientBusiness from './ClientBusiness'
import ClientBankAccounts from './ClientBankAccounts'
import ClientAssociations from './ClientAssociations'
import ClientWhitelist from './ClientWhitelist'
import AgencyAssociations from './AgencyAssociations'
import AccountPlacement from './AccountPlacement'
import shadows from '@mui/material/styles/shadows'
import { makeStyles } from '@mui/styles'
import CustomerAuditInfo from 'src/components/Audit/CustomerAuditInfo'
import { AbilityContext } from 'src/context/Can'
interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: shadows[1],
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 160,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
}))

interface ClientProfileTabProps {
  clientProfileId?: number | any
  clientData?: GetClient | any
  tabIndex?: any | undefined
}
const ClientProfileTab: React.FC<ClientProfileTabProps> = ({
  clientProfileId,
  clientData,
  tabIndex,
}: ClientProfileTabProps) => {
  const ability = useContext(AbilityContext)
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const isServicer = user && user.profile[TENANT_TYPE] === 'servicer'
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const hideComment = () => {
    if (!clientProfileId) return true

    if (clientProfileId === 0) return true

    return false
  }

  useEffect(() => {
    if (tabIndex) {
      setValue(Number(tabIndex))
    }
  }, [tabIndex])

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Client Profile" {...a11yProps(0)} />
        <Tab label="Businesses" {...a11yProps(1)} disabled={!clientProfileId} />
        <Tab label="Users" {...a11yProps(2)} disabled={!clientProfileId} />
        {clientData?.category !== 'Servicer' &&
          clientData?.category !== undefined && (
            <Tab
              label="Bank Accounts"
              {...a11yProps(3)}
              disabled={!clientProfileId}
            />
          )}
        {isInternal &&
          clientData?.category !== 'Servicer' &&
          clientData?.category !== undefined && (
            <Tab label="Comments" {...a11yProps(4)} disabled={hideComment()} />
          )}
        {isInternal &&
          clientData?.category !== 'Servicer' &&
          clientData?.category !== undefined && (
            <Tab
              label="Client Associations"
              {...a11yProps(5)}
              disabled={!clientProfileId}
            />
          )}
        {isInternal &&
          clientData?.category !== undefined &&
          clientData?.category === 'Buyer' &&
          ability.can(
            PermissionCodeAccess.CLIENT_BUYER_ACT_PLACEMENT_PERMISSION,
            'any'
          ) && (
            <Tab
              label="Account Placement"
              {...a11yProps(6)}
              disabled={!clientProfileId}
            />
          )}
        {isInternal &&
          clientData?.category !== undefined &&
          clientData?.category === 'Seller' && (
            <Tab
              label="Seller Approved Agencies"
              {...a11yProps(6)}
              disabled={!clientProfileId}
            />
          )}

        {isInternal &&
          clientData?.category !== 'Servicer' &&
          clientData?.category !== undefined && (
            <Tab
              label="Whitelist"
              {...a11yProps(clientData?.category === 'Agency' ? 6 : 7)}
              disabled={!clientProfileId}
            />
          )}
        {isInternal && (
          <Tab label="Audit" {...a11yProps(8)} disabled={!clientProfileId} />
        )}
      </Tabs>

      <TabPanel value={value} index={0}>
        <ClientProfileForm
          clientProfileId={clientProfileId}
          clientData={clientData}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!!clientProfileId && (
          <ClientBusiness clientProfileId={clientProfileId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!!clientProfileId && (
          <UserList
            tenantId={
              clientData?.tenantId || 'ADE44E98-9ED6-4AF6-916A-3A21FBED7351'
            }
            clientId={clientData?.id || 0}
          />
        )}
      </TabPanel>
      <TabPanel
        value={value}
        index={clientData?.category === 'Servicer' ? null : 3}
      >
        {!!clientProfileId && (
          <ClientBankAccounts clientProfileId={clientProfileId} />
        )}
      </TabPanel>
      {isInternal && (
        <>
          <TabPanel value={value} index={4}>
            {!!clientProfileId && <Comment clientId={clientProfileId} />}
          </TabPanel>
          <TabPanel value={value} index={5}>
            {!!clientProfileId && (
              <ClientAssociations clientProfileId={clientProfileId} />
            )}
          </TabPanel>
          {clientData?.category === 'Buyer' &&
            ability.can(
              PermissionCodeAccess.CLIENT_BUYER_ACT_PLACEMENT_PERMISSION,
              'any'
            ) && (
              <TabPanel value={value} index={6}>
                {!!clientProfileId && (
                  <AccountPlacement clientProfileId={clientProfileId} />
                )}
              </TabPanel>
            )}

          {clientData?.category === 'Seller' && (
            <TabPanel value={value} index={6}>
              {!!clientProfileId && (
                <AgencyAssociations clientProfileId={clientProfileId} />
              )}
            </TabPanel>
          )}
          <TabPanel
            value={value}
            index={
              clientData?.category === 'Agency'
                ? 6
                : clientData?.category === 'Buyer'
                ? ability.can(
                    PermissionCodeAccess.CLIENT_BUYER_ACT_PLACEMENT_PERMISSION,
                    'any'
                  )
                  ? 7
                  : 6
                : 7
            }
          >
            {!!clientProfileId && (
              <ClientWhitelist clientProfileId={clientProfileId} />
            )}
          </TabPanel>
          <TabPanel
            value={value}
            index={
              clientData?.category === 'Agency'
                ? 7
                : clientData?.category === 'Servicer'
                ? 3
                : clientData?.category === 'Buyer'
                ? ability.can(
                    PermissionCodeAccess.CLIENT_BUYER_ACT_PLACEMENT_PERMISSION,
                    'any'
                  )
                  ? 8
                  : 7
                : 8
            }
          >
            {!!clientProfileId && (
              <CustomerAuditInfo id={clientProfileId} tableName="client" />
            )}
          </TabPanel>
        </>
      )}
    </div>
  )
}

ClientProfileTab.defaultProps = {
  clientProfileId: null,
  clientData: null,
  tabIndex: null,
}

export default ClientProfileTab
