/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Switch,
  InputAdornment,
  IconButton,
  FormHelperText,
  Skeleton,
  Autocomplete,
} from '@mui/material'
import { Save, List as ListIcon } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'

import hardCodeData from 'src/utils/hardcodeData'
import {
  useAddOrUpdateSeller,
  useRenewApiKeyBusiness,
  useUploadUnmaskDataNDAFile,
} from 'src/graphql/operations/mutations/sellerBusiness'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { GET_SALES_AGENTS } from 'src/graphql/operations/queries/sellerBusiness'
import {
  BusinessSellerResponseType,
  GetSalesAgents,
} from 'src/graphql/models/sellerBusiness'
import { SELLER_BUSINESS_MANAGE } from 'src/routes'
import {
  getCountryCurrency,
  getStateDropDownName,
  isUkCountry,
  mapPropNullToString,
} from 'src/utils/common'
import { BusinessNameHistory } from 'src/components/NameHistory'
import {
  CountryDataResponse,
  GetClient,
} from 'src/graphql/models/clientProfiles'
import {
  maskZipCode,
  maskPhoneNumber,
  maskMoney,
  unmaskPhoneNumber,
  unMaskMoney,
} from 'src/utils/masker'
import { GET_COUNTRY } from 'src/graphql/operations/queries/clientProfiles'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  PermissionCodeAccess,
  PERMISSION_INFO_000,
  TENANT_TYPE,
  WEEKDAYS,
} from 'src/utils/constants'
import { CheckPermissionValues } from 'src/graphql/models/permissions'
import { CHECK_PERMISSION_VALUES } from 'src/graphql/operations/queries/permission'
import { format, parse } from 'date-fns'
import BusinessInformationSkeleton from 'src/components/BusinessSkeleton'
import { AbilityContext } from 'src/context/Can'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { notistackOptions } from 'src/configs/notistackOptions'
import { MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { BusinessStatusInfo } from 'src/components/BusinessStatusInfo'
import { DatePicker, ModalDialog } from 'everchain-uilibrary'

interface BusinessInformationProps {
  // eslint-disable-next-line react/require-default-props
  sellerBusinessId?: string | undefined
  clientId: number
  // eslint-disable-next-line react/require-default-props
  sellerData?: BusinessSellerResponseType | undefined
  // eslint-disable-next-line react/require-default-props
  client?: GetClient
  businessFeeEnabled?: boolean
}

const BusinessInformation = ({
  sellerBusinessId,
  clientId,
  sellerData,
  client,
  businessFeeEnabled,
}: BusinessInformationProps) => {
  const notifyWarning = notistackOptions('warning')
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [loadingPermission, setLoadingPermission] = useState(true)

  const { data: getSalesAgents, loading: salesAgentsLoading } =
    useQuery<GetSalesAgents>(GET_SALES_AGENTS, {
      variables: {
        isSuccessManager: false,
      },
    })

  const { data: getSuccessManager, loading: successManagerLoading } =
    useQuery<GetSalesAgents>(GET_SALES_AGENTS, {
      variables: {
        isSuccessManager: true,
      },
    })

  const MAX_SIZE_FILE = 104857600
  const acceptUpload = 'application/pdf'
  const handleFileRejected = () => {
    enqueueSnackbar('Error while trying to upload the file', notifyWarning)
  }

  const { data: countryResponse, loading: loadingCountryData } =
    useQuery<CountryDataResponse>(GET_COUNTRY)

  const countryCode = client?.countryCode || process.env.REACT_APP_COUNTRY

  const { data: permissionData, loading } = useQuery<CheckPermissionValues>(
    CHECK_PERMISSION_VALUES,
    {
      variables: {
        permissionCodes,
        encodedPermissions,
      },
      skip: !permissionCodes.length && !encodedPermissions,
    }
  )
  const ability = useContext(AbilityContext)
  const managePermission = permissionData?.checkPermissionValues.permissions

  const country =
    countryResponse && countryResponse?.countryDataResponse[0].countryCode

  const showCountryOption =
    countryResponse && countryResponse?.countryDataResponse.length > 1

  const initialDaysOfOperation = [
    WEEKDAYS[0],
    WEEKDAYS[1],
    WEEKDAYS[2],
    WEEKDAYS[3],
    WEEKDAYS[4],
  ]

  const validateZipCode = (zipCodeValue: string) => {
    if (sellerBusinessForm.values.countryCode?.toLowerCase() === 'us') {
      const regex = /^[0-9]{5}(?:-[0-9]{4})?$/
      return regex.test(zipCodeValue || '')
    }
    if (sellerBusinessForm.values.countryCode?.toLowerCase() === 'uk') {
      const regex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
      return regex.test(zipCodeValue || '')
    }
    if (sellerBusinessForm.values.countryCode?.toLowerCase() === 'ca') {
      const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/
      return regex.test(zipCodeValue || '')
    }
    return false
  }

  const sellerBusinessSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    status: Yup.string().nullable().required('Required'),
    isOriginator: Yup.boolean(),
    allowAdditionalUnmaskedData: Yup.boolean(),
    includeTLODismissals: Yup.boolean(),
    militaryScrub: Yup.boolean(),
    fF_PSA_SignatureRequired: Yup.boolean(),
    allowPortfolioSplit: Yup.boolean(),
    enableBCOReport: Yup.boolean(),
    requireBCOFinancials: Yup.boolean(),
    // bidReviewQuantity: Yup.number()
    //   .integer()
    //   .typeError('Just number')
    //   .required('Required')
    //   .min(0, 'Bid Review Quantity must be more than or equal to 0'),
    feePercent: Yup.number().min(
      0,
      'Fee Percent must be more than or equal to 0'
    ),
    forwardFlowFeePercent: Yup.number().min(
      0,
      'Forward Flow Percent must be more than or equal to 0'
    ),
    primaryContact_EMail: Yup.string().nullable().email('Invalid email'),
    customerServiceEmail: Yup.string().nullable().email('Invalid email'),
    scrubReviewThreshold: Yup.number().min(
      0,
      'Scrub Review Threshold must be more than or equal to 0'
    ),
    sellerTerms: Yup.number().min(
      0,
      'Seller Net Payment Terms must be more than or equal to 0'
    ),
    startHoursOfOperation: Yup.string().nullable(),
    endHoursOfOperation: Yup.string()
      .nullable()
      .test(
        'endLaterStart',
        'End time must be greater than start time',
        // eslint-disable-next-line func-names
        function (endHoursOfOperation, context) {
          return context.parent.startHoursOfOperation
            ? parse(endHoursOfOperation || '', 'h:mm a', new Date()) >
                parse(
                  context.parent.startHoursOfOperation,
                  'h:mm a',
                  new Date()
                )
            : true
        }
      ),
    projectedMonthlyPlacementVolumes: Yup.number().min(
      0,
      'Projected Monthly Placement Volumes must be more than or equal to 0'
    ),
    postalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
    billingPostalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
    bosSignerName: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledBOS ||
        sellerBusinessForm.values.bosSignerTitle ||
        sellerBusinessForm.values.bosSignerEmail,
      then: Yup.string().required('Required'),
    }),
    bosSignerTitle: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledBOS ||
        sellerBusinessForm.values.bosSignerName ||
        sellerBusinessForm.values.bosSignerEmail,
      then: Yup.string().required('Required'),
    }),
    bosSignerEmail: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledBOS ||
        sellerBusinessForm.values.bosSignerName ||
        sellerBusinessForm.values.bosSignerTitle,
      then: Yup.string().required('Required'),
    }),
    psaSignerName: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledPSA ||
        sellerBusinessForm.values.psaSignerTitle ||
        sellerBusinessForm.values.psaSignerEmail,
      then: Yup.string().required('Required'),
    }),
    psaSignerTitle: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledPSA ||
        sellerBusinessForm.values.psaSignerName ||
        sellerBusinessForm.values.psaSignerEmail,
      then: Yup.string().required('Required'),
    }),
    psaSignerEmail: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledPSA ||
        sellerBusinessForm.values.psaSignerName ||
        sellerBusinessForm.values.psaSignerTitle,
      then: Yup.string().required('Required'),
    }),
    mediaProvidedInBulk: Yup.boolean(),
    numberOfDaysToProvideMedia: Yup.number().when(['mediaProvidedInBulk'], {
      is: true,
      then: Yup.number().required('Required'),
      otherwise: Yup.number().nullable(),
    }),
    useAccountsProcessPortfolioFunc: Yup.boolean(),
  })

  const initialValueData = {
    businessType: 'seller',
    status: 'Application In Process',
    legalName: '',
    name: client?.name || '',
    street1: client?.street1 ?? '',
    street2: client?.street2 ?? '',
    city: client?.city ?? '',
    stateCode: client?.stateCode ?? '',
    postalCode: client?.postalCode ?? '',
    countryCode: client?.countryCode ?? country,
    corpHQPhoneNumber: client?.corpHQPhoneNumber || '',
    customerServicePhoneNumber: client?.customerServicePhoneNumber || '',
    customerServiceEmail: client?.customerServiceEmail || '',
    primaryContact_LastName: client?.primaryContact_LastName || '',
    primaryContact_FirstName: client?.primaryContact_FirstName || '',
    primaryContact_EMail: client?.primaryContact_EMail || '',
    primaryContact_OfficePhone: client?.primaryContact_OfficePhone || '',
    primaryContact_MobilePhone: client?.primaryContact_MobilePhone || '',
    salesDirectorTitle: client?.salesDirectorTitle || '',
    recoverSuccessManagerTitle: client?.recoverSuccessManagerTitle || '',
    importFromId: '',
    insurance_Name: '',
    insurance_PolicyType: '',
    insurance_CoverageAmount: 0,
    insurance_ExpirationDate: '',
    insurance_Comment: '',
    defaultBankAccountId: '',
    softwareUsed: '',
    feePercent: 3,
    forwardFlowFeePercent: 3,
    minimumFee: 0,
    enforceMinimumFee: false,
    isOriginator: client?.category.toLowerCase() === 'seller',
    holdScrub: false,
    militaryScrub: false,
    militaryCertificates: 0,
    scrubReviewThreshold: 1,
    enableBCOReport: false,
    includeTLODismissals: true,
    dTCanList: false,
    dTCanRemoveAccountsFromPortfolio: false,
    dTCanAcceptBid: false,
    dTCanUploadDownloadPSA: false,
    dTCanConfirmFundsReceived: false,
    dTCanCreatePostSaleRequest: false,
    dTCanRespondToPostSaleRequest: false,
    dTCanClosePostSaleRequest: false,
    dTCanMaintainComplaints: false,
    permissionsLastUpdatedByUserId: '',
    permissionsLastUpdatedUTC: '',
    signer_FullName: '',
    signer_Title: '',
    bankName: '',
    accountTypeCode: 'Checking',
    routingNumber: '',
    nameOnAccount: '',
    accountNumber: '',
    bankAccountStatus: 'Active',
    buyerBidAccessOption: 0,
    // bidReviewQuantity: 2,
    showWinningBid: false,
    requireBCOFinancials: false,
    allowAdditionalUnmaskedData: false,
    isUnmaskDataNDARequired: false,
    sellerTerms: 5,
    salesAgentId: '',
    clientSuccessUserId: '',
    salesAgentDisplayName: '',
    originationState: '',
    disclosure: '',
    startHoursOfOperation: null,
    endHoursOfOperation: null,
    projectedMonthlyPlacementVolumes: 0,
    fF_PSA_SignatureRequired: false,
    enableWebhookPlacement: false,
    allowPortfolioSplit: false,
    unmaskDataNDABlobUri: '',
    billingStreet1: client?.street1 ?? '',
    billingStreet2: client?.street2 ?? '',
    billingCity: client?.city ?? '',
    billingStateCode: client?.stateCode ?? '',
    billingPostalCode: client?.postalCode ?? '',
    bosSignerName: '',
    bosSignerTitle: '',
    bosSignerEmail: '',
    psaSignerName: '',
    psaSignerTitle: '',
    psaSignerEmail: '',
    apiKey: '',
    consumerPaymentInstruction: '',
    enablePartnershipScoring: false,
    mediaFileNamingConvention: '[Account ID]_[Document Type]',
    mediaProvidedInBulk: false,
    numberOfDaysToProvideMedia: null,
    companyWebsiteAddress: '',
    taxId: '',
    businessTypeLLC: '',
    stateOfIncorporation: '',
    dateOfEstablishment: null,
    useAccountsProcessPortfolioFunc: false,
  }
  const { user, profileClient } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('')

  const [initialValues, setInitialValues] = useState<any>(initialValueData)
  const [openNameHistory, setOpenNameHistory] = useState(false)
  const [fileUploaded, setFileUploaded] = useState<boolean>(
    initialValueData.unmaskDataNDABlobUri !== ''
  )
  const history = useHistory()
  const [daysOfOperation, setDaysOfOperation] = useState(initialDaysOfOperation)
  const [newApiKey, setNewApiKey] = useState<string>()

  const { enqueueSnackbar } = useSnackbar()
  const profileClientCountry =
    sellerData?.countryCode ||
    client?.countryCode ||
    process.env.REACT_APP_COUNTRY

  const handleAddOrUpdateBusinessCompleted = (data: any) => {
    if (data) {
      const text = sellerBusinessId ? 'Update' : 'Create'

      enqueueSnackbar(`${text} seller successful`, {
        variant: 'success',
      })
      if (data.addOrUpdateSeller && data.addOrUpdateSeller.id) {
        history.push(
          `${SELLER_BUSINESS_MANAGE}/${clientId}/${data.addOrUpdateSeller.id}`
        )
      }
    }
  }

  const { addOrUpdateSeller, loading: addOrUpdateloading } =
    useAddOrUpdateSeller(handleAddOrUpdateBusinessCompleted)

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.text) {
      setError('Required')
    } else {
      setError('')
    }
    return errors
  }

  const sellerBusinessForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: sellerBusinessSchema,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const sellerRequest = {
        name: values.name || null,
        softwareUsed: values.softwareUsed || null,
        isOriginator: values.isOriginator,
        city: values.city || null,
        status: values.status || null,
        countryCode: values.countryCode || null,
        stateCode: values.stateCode || null,
        postalCode: values.postalCode || null,
        street1: values.street1 || null,
        street2: values.street2 || null,
        signer_FullName: values.signer_FullName || null,
        signer_Title: values.signer_Title || null,
        feePercent: values.feePercent
          ? parseFloat(values.feePercent)
          : values.feePercent,
        forwardFlowFeePercent: values.forwardFlowFeePercent
          ? parseFloat(values.forwardFlowFeePercent)
          : values.forwardFlowFeePercent,
        minimumFee: values.minimumFee
          ? Number(unMaskMoney(values.minimumFee))
          : null,
        enforceMinimumFee: values.enforceMinimumFee,
        // bidReviewQuantity: Number(values.bidReviewQuantity) ?? 3,
        allowAdditionalUnmaskedData: values.allowAdditionalUnmaskedData,
        isUnmaskDataNDARequired: values.isUnmaskDataNDARequired,
        militaryScrub: values.militaryScrub,
        includeTLODismissals: values.includeTLODismissals,
        militaryCertificates: values.militaryCertificates,
        enableBCOReport: values.enableBCOReport,
        requireBCOFinancials: values.requireBCOFinancials,
        sellerTerms: isNaN(values.sellerTerms) ? 5 : Number(values.sellerTerms),
        businessType: 'seller',
        corpHQPhoneNumber: values.corpHQPhoneNumber
          ? unmaskPhoneNumber(values.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: values.customerServicePhoneNumber
          ? unmaskPhoneNumber(values.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: values.primaryContact_OfficePhone
          ? unmaskPhoneNumber(values.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: values.primaryContact_MobilePhone
          ? unmaskPhoneNumber(values.primaryContact_MobilePhone, countryCode)
          : null,
        customerServiceEmail: values.customerServiceEmail || null,
        primaryContact_LastName: values.primaryContact_LastName || null,
        primaryContact_FirstName: values.primaryContact_FirstName || null,
        primaryContact_EMail: values.primaryContact_EMail || null,
        legalName: values.legalName || null,
        importFromId: values.importFromId || null,
        insurance_Name: values.insurance_Name || null,
        insurance_PolicyType: values.insurance_PolicyType || null,
        insurance_CoverageAmount: values.insurance_CoverageAmount || null,
        insurance_ExpirationDate: values.insurance_ExpirationDate || null,
        insurance_Comment: values.insurance_Comment || null,
        defaultBankAccountId: values.defaultBankAccountId || null,
        holdScrub: values.holdScrub,
        scrubReviewThreshold: values.scrubReviewThreshold || null,
        dTCanList: values.dTCanList,
        dTCanRemoveAccountsFromPortfolio:
          values.dTCanRemoveAccountsFromPortfolio,
        dTCanAcceptBid: values.dTCanAcceptBid,
        dTCanUploadDownloadPSA: values.dTCanUploadDownloadPSA,
        dTCanConfirmFundsReceived: values.dTCanConfirmFundsReceived,
        dTCanCreatePostSaleRequest: values.dTCanCreatePostSaleRequest,
        dTCanRespondToPostSaleRequest: values.dTCanRespondToPostSaleRequest,
        dTCanClosePostSaleRequest: values.dTCanClosePostSaleRequest,
        dTCanMaintainComplaints: values.dTCanMaintainComplaints,
        permissionsLastUpdatedByUserId:
          values.permissionsLastUpdatedByUserId || null,
        permissionsLastUpdatedUTC: values.permissionsLastUpdatedUTC || null,
        bankName: values.bankName || null,
        accountTypeCode: values.accountTypeCode,
        routingNumber: values.routingNumber || null,
        nameOnAccount: values.nameOnAccount || null,
        accountNumber: values.accountNumber || null,
        bankAccountStatus: values.bankAccountStatus,
        buyerBidAccessOption: values.buyerBidAccessOption,
        showWinningBid: values.showWinningBid,
        salesAgentId: values.salesAgentId || null,
        clientSuccessUserId: values.clientSuccessUserId || null,
        salesAgentDisplayName: values.salesAgentDisplayName || null,
        originationState: values.originationState || null,
        disclosure: values.disclosure || null,
        startHoursOfOperation: values?.startHoursOfOperation,
        endHoursOfOperation: values?.endHoursOfOperation,
        daysOfOperation: daysOfOperation.map((x) => x.value),
        projectedMonthlyPlacementVolumes:
          values?.projectedMonthlyPlacementVolumes || null,
        fF_PSA_SignatureRequired: values?.fF_PSA_SignatureRequired || false,
        allowPortfolioSplit: values?.allowPortfolioSplit || false,
        billingStreet1: values.billingStreet1 || null,
        billingStreet2: values.billingStreet2 || null,
        billingCity: values.billingCity || null,
        billingStateCode: values.billingStateCode || null,
        billingPostalCode: values.billingPostalCode || null,
        bosSignerName: values.bosSignerName || null,
        bosSignerTitle: values.bosSignerTitle || null,
        bosSignerEmail: values.bosSignerEmail || null,
        psaSignerName: values.psaSignerName || null,
        psaSignerTitle: values.psaSignerTitle || null,
        psaSignerEmail: values.psaSignerEmail || null,
        apiKey: values.apiKey || null,
        consumerPaymentInstruction: values.consumerPaymentInstruction || null,
        enablePartnershipScoring: values.enablePartnershipScoring || false,
        mediaFileNamingConvention: values.mediaFileNamingConvention || '',
        mediaProvidedInBulk: values.mediaProvidedInBulk || false,
        numberOfDaysToProvideMedia: values.numberOfDaysToProvideMedia || null,
        dateOfEstablishment: values.dateOfEstablishment
          ? new Date(values.dateOfEstablishment)
          : null,
        companyWebsiteAddress: values.companyWebsiteAddress || null,
        businessTypeLLC: values.businessTypeLLC || null,
        taxId: values.taxId || null,
        stateOfIncorporation: values.stateOfIncorporation || null,
        useAccountsProcessPortfolioFunc:
          values.useAccountsProcessPortfolioFunc || false,
      }
      if (sellerBusinessId) {
        addOrUpdateSeller({
          variables: {
            seller: {
              id: sellerBusinessId,
              clientId: Number(clientId),
              ...sellerRequest,
            },
          },
          refetchQueries: ['GetSeller'],
        })
      } else {
        addOrUpdateSeller({
          variables: {
            seller: {
              ...sellerRequest,
              clientId: Number(clientId),
            },
          },
          refetchQueries: ['GetSeller'],
        })
      }
    },
  })
  const { uploadUnmaskDataNDAFile, loading: fileUploading } =
    useUploadUnmaskDataNDAFile({
      onCompleted: (data: any) => {
        if (data) {
          setFileUploaded(true)
          enqueueSnackbar(
            'File uploaded successfully',
            notistackOptions('success')
          )
        } else {
          enqueueSnackbar(
            'Error while trying to upload the file',
            notistackOptions('warning')
          )
        }
      },
    })
  const handleFileUpload = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length) {
      uploadUnmaskDataNDAFile({
        variables: {
          businessId: sellerBusinessId,
          file: acceptedFiles[0],
        },
      })
    }
  }

  const { renewApiKeyBusiness, loading: renewLoading } = useRenewApiKeyBusiness(
    {
      onCompleted: (data: any) => {
        if (data) {
          setNewApiKey(data.renewApiKeyBusiness)
          enqueueSnackbar('API Key renewed successfully', {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          })
        } else {
          enqueueSnackbar('Error while trying to renew the API Key', {
            variant: 'warning',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          })
        }
      },
    }
  )
  const handleRenewApiKey = () => {
    renewApiKeyBusiness({
      variables: {
        businessId: sellerBusinessId,
      },
    })
  }

  const onDownload = () => {
    const uri = sellerBusinessForm.values.unmaskDataNDABlobUri ?? ''
    if (uri === '') return
    window.location.href = uri
  }

  const handleNameHistoryToogle = () => {
    setOpenNameHistory(!openNameHistory)
  }

  const handleCountryChange = (value: any, name: any) => {
    sellerBusinessForm.setFieldValue('stateCode', null, true)
    sellerBusinessForm.setFieldValue('billingStateCode', null, true)
    sellerBusinessForm.setFieldValue('postalCode', '', true)
    sellerBusinessForm.setFieldValue('billingPostalCode', '', true)
    sellerBusinessForm.setFieldValue(name, value, true)
  }

  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(PermissionCodeAccess).map(
        ([pName, pCode]) => pCode
      )
      setPermissionCodes(permissionCode)
      setEncodedPermissions(profile[PERMISSION_INFO_000])
      setLoadingPermission(false)
    }
  }, [user])

  useEffect(() => {
    setFileUploaded(sellerBusinessForm.values.unmaskDataNDABlobUri !== '')
  }, [sellerBusinessForm.values.unmaskDataNDABlobUri])

  useEffect(() => {
    if (sellerData) {
      setInitialValues((prevState: BusinessSellerResponseType) => ({
        ...prevState,
        ...mapPropNullToString(sellerData),
        minimumFee: sellerData.minimumFee
          ? maskMoney(parseFloat(sellerData.minimumFee.toString()).toFixed(2))
          : sellerData.minimumFee,
        corpHQPhoneNumber: sellerData.corpHQPhoneNumber
          ? maskPhoneNumber(sellerData.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: sellerData.customerServicePhoneNumber
          ? maskPhoneNumber(sellerData.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: sellerData.primaryContact_OfficePhone
          ? maskPhoneNumber(sellerData.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: sellerData.primaryContact_MobilePhone
          ? maskPhoneNumber(sellerData.primaryContact_MobilePhone, countryCode)
          : null,
        feePercent:
          isNaN(sellerData.feePercent) || sellerData.feePercent === null
            ? null
            : sellerData.feePercent === 0
            ? Number(sellerData.feePercent)
            : Number(parseFloat(sellerData.feePercent.toString()).toFixed(2)),
        forwardFlowFeePercent:
          isNaN(sellerData.forwardFlowFeePercent) ||
          sellerData.forwardFlowFeePercent === null
            ? null
            : sellerData.forwardFlowFeePercent === 0
            ? Number(sellerData.forwardFlowFeePercent)
            : Number(
                parseFloat(sellerData.forwardFlowFeePercent.toString()).toFixed(
                  2
                )
              ),
        dateOfEstablishment: sellerData.dateOfEstablishment
          ? new Date(sellerData.dateOfEstablishment)
          : null,
      }))
      setDaysOfOperation(
        sellerData.daysOfOperation.length > 0
          ? WEEKDAYS.filter(
              (x) => sellerData.daysOfOperation.indexOf(x.value) !== -1
            )
          : initialDaysOfOperation
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerData])

  const onChangeTime = (date: any, field: string) => {
    sellerBusinessForm.setFieldValue(field, format(new Date(date), 'hh:mm a'))
  }
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )
  const hasFeePermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_FEES_PERMISSION,
    'any'
  )
  const hasCompliancePermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_COMPLIANCE_PERMISSION,
    'any'
  )
  const hasPermissionScrub = ability.can(
    PermissionCodeAccess.CLIENT_MILITARY_SCRUB_PERMISSION,
    'any'
  )
  const hasStatusUpdatePermission = ability.can(
    PermissionCodeAccess.CLIENT_SELLER_STATUS_PERMISSION,
    'any'
  )
  if ((!managePermission && loadingPermission) || loading) {
    return <BusinessInformationSkeleton />
  }

  const isUk = isUkCountry(client?.countryCode || process.env.REACT_APP_COUNTRY)

  return (
    <form onSubmit={sellerBusinessForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">Seller Business Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              disabled={
                !!sellerBusinessId || !isInternal || !hasBasicInfoPermission
              }
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.name}
              value={sellerBusinessForm.values.name || ''}
              helperText={<>{sellerBusinessForm.errors.name}</>}
              InputProps={{
                endAdornment: sellerBusinessId && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleNameHistoryToogle()
                      }}
                    >
                      <ListIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} display="flex" flexDirection="row">
            <TextField
              fullWidth
              label="Status"
              select
              name="status"
              disabled={!isInternal || !hasStatusUpdatePermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.status}
              value={sellerBusinessForm.values.status}
              helperText={<>{sellerBusinessForm.errors.status}</>}
            >
              {hardCodeData.getBusinessStatus().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <BusinessStatusInfo />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Legal Name"
              name="legalName"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.legalName}
              value={sellerBusinessForm.values.legalName || ''}
              helperText={<>{sellerBusinessForm.errors.legalName}</>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-companyWebsiteAddress"
              label="Company Website Address"
              name="companyWebsiteAddress"
              onChange={sellerBusinessForm.handleChange}
              value={sellerBusinessForm.values.companyWebsiteAddress || ''}
              error={!!sellerBusinessForm.errors.companyWebsiteAddress}
              helperText={
                <>{sellerBusinessForm.errors.companyWebsiteAddress}</>
              }
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-stateofIncorporation"
              label="State of Incorporation"
              name="stateOfIncorporation"
              onChange={sellerBusinessForm.handleChange}
              value={sellerBusinessForm.values.stateOfIncorporation || ''}
              error={!!sellerBusinessForm.errors.stateOfIncorporation}
              helperText={<>{sellerBusinessForm.errors.stateOfIncorporation}</>}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-taxID"
              label={'Tax ID #'}
              name="taxId"
              inputProps={{
                maxLength: 20,
              }}
              onChange={sellerBusinessForm.handleChange}
              value={sellerBusinessForm.values.taxId || ''}
              error={!!sellerBusinessForm.errors.taxId}
              helperText={<>{sellerBusinessForm.errors.taxId}</>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-businessType"
              label={'Business Type (LLC)'}
              name="businessTypeLLC"
              onChange={sellerBusinessForm.handleChange}
              value={sellerBusinessForm.values.businessTypeLLC || ''}
              error={!!sellerBusinessForm.errors.businessTypeLLC}
              helperText={<>{sellerBusinessForm.errors.businessTypeLLC}</>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              label="Date of Establishment"
              disabled={!isInternal || !hasBasicInfoPermission}
              name="dateOfEstablishment"
              onChange={(date) => {
                sellerBusinessForm.setFieldValue(
                  'dateOfEstablishment',
                  date,
                  false
                )
              }}
              value={sellerBusinessForm.values.dateOfEstablishment}
              errorMessage={sellerBusinessForm.errors.dateOfEstablishment?.toString()}
              country={profileClient?.Country}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={!isInternal}
                  onChange={sellerBusinessForm.handleChange}
                  name="enablePartnershipScoring"
                  value={sellerBusinessForm.values.enablePartnershipScoring}
                  checked={sellerBusinessForm.values.enablePartnershipScoring}
                />
              }
              label="Enable partnership scoring"
            />
          </Grid>

          {isInternal && (
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Software Used"
                name="softwareUsed"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={sellerBusinessForm.handleChange}
                error={!!sellerBusinessForm.errors.softwareUsed}
                value={sellerBusinessForm.values.softwareUsed || ''}
                helperText={<>{sellerBusinessForm.errors.softwareUsed}</>}
              />
            </Grid>
          )}
          {isInternal && (
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label={`EverChain Assigned ${sellerBusinessForm.values.salesDirectorTitle}`}
                select
                name="salesAgentId"
                onChange={(e) => {
                  sellerBusinessForm.handleChange(e)
                  sellerBusinessForm.setFieldValue(
                    'salesAgentDisplayName',
                    getSalesAgents?.getSalesAgents?.find(
                      (x) => x.userId === e?.target?.value
                    )?.displayName
                  )
                }}
                error={!!sellerBusinessForm.errors.salesAgentId}
                value={sellerBusinessForm.values.salesAgentId}
                helperText={<>{sellerBusinessForm.errors.salesAgentId}</>}
                disabled={
                  salesAgentsLoading || !isInternal || !hasBasicInfoPermission
                }
              >
                {getSalesAgents?.getSalesAgents.length &&
                !salesAgentsLoading ? (
                  getSalesAgents.getSalesAgents.map((option, i) => (
                    <MenuItem
                      data-test-cy={`salesAgentId-item-${i}`}
                      key={option.userId}
                      value={option.userId}
                    >
                      {option.displayName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Loading</MenuItem>
                )}
              </TextField>
            </Grid>
          )}
          {isInternal && (
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label={`${sellerBusinessForm.values.recoverSuccessManagerTitle}`}
                select
                name="clientSuccessUserId"
                onChange={sellerBusinessForm.handleChange}
                error={!!sellerBusinessForm.errors.clientSuccessUserId}
                value={sellerBusinessForm.values.clientSuccessUserId}
                helperText={
                  <>{sellerBusinessForm.errors.clientSuccessUserId}</>
                }
                disabled={
                  successManagerLoading ||
                  !isInternal ||
                  !hasBasicInfoPermission
                }
              >
                {getSuccessManager?.getSalesAgents.length &&
                !successManagerLoading ? (
                  getSuccessManager.getSalesAgents.map((option) => (
                    <MenuItem key={option.userId} value={option.userId}>
                      {option.displayName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Loading</MenuItem>
                )}
              </TextField>
            </Grid>
          )}
          {isInternal && (
            <Grid item xs={12} lg={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    onChange={sellerBusinessForm.handleChange}
                    name="isOriginator"
                    checked={sellerBusinessForm.values.isOriginator}
                    value={sellerBusinessForm.values.isOriginator}
                    disabled={!isInternal || !hasBasicInfoPermission}
                  />
                }
                label="Is Originator"
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Hours of Operation (PST)</Typography>
        <Grid
          container
          spacing={8}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xs={3} lg={3}>
            <MobileTimePicker
              label="Start"
              disabled={!isInternal || !hasBasicInfoPermission}
              value={
                sellerBusinessForm.values.startHoursOfOperation
                  ? dayjs(
                      parse(
                        sellerBusinessForm.values.startHoursOfOperation,
                        'h:mm a',
                        new Date()
                      )
                    )
                  : sellerBusinessForm.values.startHoursOfOperation
              }
              onChange={(date) => onChangeTime(date, 'startHoursOfOperation')}
              ampm
              minutesStep={30}
              orientation="landscape"
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <MobileTimePicker
              label="End"
              disabled={!isInternal || !hasBasicInfoPermission}
              value={
                sellerBusinessForm.values.endHoursOfOperation
                  ? dayjs(
                      parse(
                        sellerBusinessForm.values.endHoursOfOperation,
                        'h:mm a',
                        new Date()
                      )
                    )
                  : sellerBusinessForm.values.endHoursOfOperation
              }
              onChange={(date) => onChangeTime(date, 'endHoursOfOperation')}
              ampm
              minutesStep={30}
              orientation="landscape"
            />
            {sellerBusinessForm.errors.endHoursOfOperation && (
              <FormHelperText style={{ color: '#d32f2f' }}>
                {<>{sellerBusinessForm.errors.endHoursOfOperation}</>}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} lg={6}>
            <Autocomplete
              id="daysOfOperation"
              multiple
              options={WEEKDAYS}
              disabled={!isInternal || !hasBasicInfoPermission}
              getOptionLabel={(option) => option.day}
              value={daysOfOperation}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={!isInternal || !hasBasicInfoPermission}
                  variant="standard"
                  name="daysOfOperation"
                  label="Days of the Week"
                  placeholder="Chose one or more days of the week"
                  error={!!sellerBusinessForm.errors.daysOfOperation}
                />
              )}
              onChange={(ev, values) => {
                values && values.length > 0
                  ? setDaysOfOperation(values)
                  : enqueueSnackbar('You need to select at least one weekday', {
                      variant: 'error',
                    })
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Address</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street"
              name="street1"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.street1}
              value={sellerBusinessForm.values.street1 || ''}
              helperText={<>{sellerBusinessForm.errors.street1}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street 2"
              name="street2"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.street2}
              value={sellerBusinessForm.values.street2 || ''}
              helperText={<>{sellerBusinessForm.errors.street2}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="City"
              name="city"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.city}
              value={sellerBusinessForm.values.city || ''}
              helperText={<>{sellerBusinessForm.errors.city}</>}
            />
          </Grid>
          {showCountryOption && (
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label="Country"
                  name="countryCode"
                  select
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={({ target: { value, name } }) => {
                    handleCountryChange(value, name)
                  }}
                  error={!!sellerBusinessForm.errors.countryCode}
                  value={sellerBusinessForm.values.countryCode}
                  helperText={<>{sellerBusinessForm.errors.countryCode}</>}
                >
                  {countryResponse?.countryDataResponse.map((option) => (
                    <MenuItem
                      key={option.countryCode}
                      value={option.countryCode}
                    >
                      {option.countryDescription}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          )}
          <Grid item xs={12} lg={4}>
            {loadingCountryData ? (
              <Skeleton variant="rectangular" width="100%" />
            ) : (
              <TextField
                fullWidth
                disabled={!isInternal || !hasBasicInfoPermission}
                label={getStateDropDownName(country)}
                name="stateCode"
                select
                onChange={sellerBusinessForm.handleChange}
                error={!!sellerBusinessForm.errors.stateCode}
                value={sellerBusinessForm.values.stateCode}
                helperText={<>{sellerBusinessForm.errors.stateCode}</>}
              >
                {countryResponse?.countryDataResponse
                  .find(
                    (x) =>
                      x.countryCode === sellerBusinessForm.values.countryCode
                  )
                  ?.stateInfo.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.description}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={!isInternal || !hasBasicInfoPermission}
              label="Postal Code"
              name="postalCode"
              onChange={({ target: { value, name } }) => {
                const masked = maskZipCode(
                  value,
                  sellerBusinessForm.values.countryCode
                )
                sellerBusinessForm.setFieldValue(name, masked, true)
              }}
              error={!!sellerBusinessForm.errors.postalCode}
              value={sellerBusinessForm.values.postalCode || ''}
              helperText={<>{sellerBusinessForm.errors.postalCode}</>}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Billing Address</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street"
              name="billingStreet1"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.billingStreet1}
              value={sellerBusinessForm.values.billingStreet1 || ''}
              helperText={<>{sellerBusinessForm.errors.billingStreet1}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street 2"
              name="billingStreet2"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.billingStreet2}
              value={sellerBusinessForm.values.billingStreet2 || ''}
              helperText={<>{sellerBusinessForm.errors.billingStreet2}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="City"
              name="billingCity"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.billingCity}
              value={sellerBusinessForm.values.billingCity || ''}
              helperText={<>{sellerBusinessForm.errors.billingCity}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            {loadingCountryData ? (
              <Skeleton variant="rectangular" width="100%" />
            ) : (
              <TextField
                fullWidth
                label={getStateDropDownName(country)}
                name="billingStateCode"
                select
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={sellerBusinessForm.handleChange}
                error={!!sellerBusinessForm.errors.billingStateCode}
                value={sellerBusinessForm.values.billingStateCode}
                helperText={<>{sellerBusinessForm.errors.billingStateCode}</>}
              >
                {countryResponse?.countryDataResponse
                  .find(
                    (x) =>
                      x.countryCode === sellerBusinessForm.values.countryCode
                  )
                  ?.stateInfo.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.description}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Postal Code"
              name="billingPostalCode"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={({ target: { value, name } }) => {
                const masked = maskZipCode(
                  value,
                  sellerBusinessForm.values.countryCode
                )
                sellerBusinessForm.setFieldValue(name, masked, true)
              }}
              error={!!sellerBusinessForm.errors.billingPostalCode}
              value={sellerBusinessForm.values.billingPostalCode || ''}
              helperText={<>{sellerBusinessForm.errors.billingPostalCode}</>}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Seller Contact Information</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              disabled={!isInternal || !hasBasicInfoPermission}
              label="HQ Phone"
              name="corpHQPhoneNumber"
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                sellerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!sellerBusinessForm.errors.corpHQPhoneNumber}
              value={sellerBusinessForm.values.corpHQPhoneNumber || ''}
              helperText={<>{sellerBusinessForm.errors.corpHQPhoneNumber}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Customer Services Phone"
              name="customerServicePhoneNumber"
              disabled={!isInternal || !hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                sellerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!sellerBusinessForm.errors.customerServicePhoneNumber}
              value={sellerBusinessForm.values.customerServicePhoneNumber || ''}
              helperText={
                <>{sellerBusinessForm.errors.customerServicePhoneNumber}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Customer Services E-mail"
              name="customerServiceEmail"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.customerServiceEmail}
              value={sellerBusinessForm.values.customerServiceEmail || ''}
              helperText={<>{sellerBusinessForm.errors.customerServiceEmail}</>}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Primary Contact</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Last Name"
              name="primaryContact_LastName"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.primaryContact_LastName}
              value={sellerBusinessForm.values.primaryContact_LastName || ''}
              helperText={
                <>{sellerBusinessForm.errors.primaryContact_LastName}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="First Name"
              name="primaryContact_FirstName"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.primaryContact_FirstName}
              value={sellerBusinessForm.values.primaryContact_FirstName || ''}
              helperText={
                <>{sellerBusinessForm.errors.primaryContact_FirstName}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="E-mail"
              name="primaryContact_EMail"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.primaryContact_EMail}
              value={sellerBusinessForm.values.primaryContact_EMail || ''}
              helperText={<>{sellerBusinessForm.errors.primaryContact_EMail}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Direct (Phone)"
              name="primaryContact_OfficePhone"
              disabled={!isInternal || !hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                sellerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!sellerBusinessForm.errors.primaryContact_OfficePhone}
              value={sellerBusinessForm.values.primaryContact_OfficePhone || ''}
              helperText={
                <>{sellerBusinessForm.errors.primaryContact_OfficePhone}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Mobile"
              disabled={!isInternal || !hasBasicInfoPermission}
              name="primaryContact_MobilePhone"
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                sellerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!sellerBusinessForm.errors.primaryContact_MobilePhone}
              value={sellerBusinessForm.values.primaryContact_MobilePhone || ''}
              helperText={
                <>{sellerBusinessForm.errors.primaryContact_MobilePhone}</>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Signer</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Full Name"
              name="signer_FullName"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.signer_FullName}
              value={sellerBusinessForm.values.signer_FullName || ''}
              helperText={<>{sellerBusinessForm.errors.signer_FullName}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Title"
              name="signer_Title"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.signer_Title}
              value={sellerBusinessForm.values.signer_Title || ''}
              helperText={<>{sellerBusinessForm.errors.signer_Title}</>}
            />
          </Grid>
        </Grid>
      </Box>
      {isInternal && (
        <>
          <Box mb={12}>
            <Typography variant="h6">Seller Configuration</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Fee Percent"
                  name="feePercent"
                  disabled={
                    !isInternal || !hasFeePermission || businessFeeEnabled
                  }
                  onChange={({ target: { value, name } }) => {
                    sellerBusinessForm.setFieldValue(name, value, true)
                  }}
                  error={!!sellerBusinessForm.errors.feePercent}
                  value={
                    !isNaN(sellerBusinessForm.values.feePercent)
                      ? sellerBusinessForm.values.feePercent
                      : ''
                  }
                  helperText={<>{sellerBusinessForm.errors.feePercent}</>}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Forward-Flow Fee"
                  type="number"
                  disabled={
                    !isInternal || !hasFeePermission || businessFeeEnabled
                  }
                  name="forwardFlowFeePercent"
                  onChange={({ target: { value, name } }) => {
                    sellerBusinessForm.setFieldValue(name, value, true)
                  }}
                  error={!!sellerBusinessForm.errors.forwardFlowFeePercent}
                  value={
                    !isNaN(sellerBusinessForm.values.forwardFlowFeePercent)
                      ? sellerBusinessForm.values.forwardFlowFeePercent
                      : ''
                  }
                  helperText={
                    <>{sellerBusinessForm.errors.forwardFlowFeePercent}</>
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      disabled={
                        !isInternal || !hasFeePermission || businessFeeEnabled
                      }
                      checked={sellerBusinessForm.values.enforceMinimumFee}
                      onChange={sellerBusinessForm.handleChange}
                      name="enforceMinimumFee"
                      value={sellerBusinessForm.values.enforceMinimumFee}
                    />
                  }
                  label="Enforce Minimum Fee"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  disabled={
                    !isInternal || !hasFeePermission || businessFeeEnabled
                  }
                  label="Minimum Fee"
                  name="minimumFee"
                  onChange={({ target: { value, name } }) => {
                    const masked = maskMoney(value)
                    sellerBusinessForm.setFieldValue(name, masked, false)
                  }}
                  error={!!sellerBusinessForm.errors.minimumFee}
                  value={sellerBusinessForm.values.minimumFee || ''}
                  helperText={<>{sellerBusinessForm.errors.minimumFee}</>}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCountryCurrency(profileClientCountry)}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  disabled={!isInternal || !hasFeePermission}
                  label="Seller Net Payment Terms"
                  type="number"
                  name="sellerTerms"
                  onChange={({ target: { value, name } }) => {
                    sellerBusinessForm.setFieldValue(name, value, false)
                  }}
                  error={!!sellerBusinessForm.errors.sellerTerms}
                  value={sellerBusinessForm.values.sellerTerms || ''}
                  helperText={<>{sellerBusinessForm.errors.sellerTerms}</>}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={12}>
            <Typography variant="h6">
              Portfolio Sales & Auction Preferences
            </Typography>
            <Grid container spacing={4}>
              {/* <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    disabled={!hasBasicInfoPermission}
                    label="Bid Review Quantity"
                    name="bidReviewQuantity"
                    onChange={({ target: { value, name } }) => {
                      sellerBusinessForm.setFieldValue(name, value, false)
                    }}
                    error={!!sellerBusinessForm.errors.bidReviewQuantity}
                    value={sellerBusinessForm.values.bidReviewQuantity}
                    helperText={sellerBusinessForm.errors.bidReviewQuantity}
                  />
                </Grid> */}
              <Grid item xs={12} lg={4}>
                {loadingCountryData ? (
                  <Skeleton variant="rectangular" width="100%" />
                ) : (
                  <TextField
                    fullWidth
                    label="Origination State PSA Token"
                    name="originationState"
                    select
                    disabled={!isInternal || !hasBasicInfoPermission}
                    onChange={sellerBusinessForm.handleChange}
                    value={sellerBusinessForm.values.originationState}
                  >
                    {countryResponse?.countryDataResponse
                      .find(
                        (x) =>
                          x.countryCode ===
                          sellerBusinessForm.values.countryCode
                      )
                      ?.stateInfo.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.description}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      disabled={!isInternal || !hasBasicInfoPermission}
                      onChange={sellerBusinessForm.handleChange}
                      name="fF_PSA_SignatureRequired"
                      value={sellerBusinessForm.values.fF_PSA_SignatureRequired}
                      checked={
                        sellerBusinessForm.values.fF_PSA_SignatureRequired
                      }
                      title="Forward Flow PSA Signature Required"
                    />
                  }
                  label="Forward Flow PSA Signature Required"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      disabled={!isInternal || !hasBasicInfoPermission}
                      onChange={sellerBusinessForm.handleChange}
                      name="allowPortfolioSplit"
                      value={sellerBusinessForm.values.allowPortfolioSplit}
                      checked={sellerBusinessForm.values.allowPortfolioSplit}
                      title="Allow Portfolio Split"
                    />
                  }
                  label="Allow Portfolio Split"
                />
              </Grid>
            </Grid>
          </Box>
          {isInternal && (
            <Box mb={12}>
              <Typography variant="h6">Disclosure</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={9} md={9} lg={9} />
                <TextField
                  id="select-disclosure"
                  label="Disclosure"
                  name="disclosure"
                  multiline
                  disabled={!isInternal || !hasBasicInfoPermission}
                  rows={4}
                  onChange={sellerBusinessForm.handleChange}
                  value={sellerBusinessForm.values.disclosure || ''}
                  error={!!sellerBusinessForm.errors.disclosure}
                  helperText={<>{sellerBusinessForm.errors.disclosure}</>}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Box>
          )}

          <Box mb={12}>
            <Typography variant="h6">Unmasked Data Request</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={5}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={sellerBusinessForm.handleChange}
                      name="allowAdditionalUnmaskedData"
                      disabled={!isInternal || !hasBasicInfoPermission}
                      checked={
                        sellerBusinessForm.values.allowAdditionalUnmaskedData
                      }
                      value={
                        sellerBusinessForm.values.allowAdditionalUnmaskedData
                      }
                    />
                  }
                  label="Allow requests for additional unmasked data?"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={sellerBusinessForm.handleChange}
                      name="isUnmaskDataNDARequired"
                      disabled={!isInternal || !hasBasicInfoPermission}
                      checked={
                        sellerBusinessForm.values.isUnmaskDataNDARequired
                      }
                      value={sellerBusinessForm.values.isUnmaskDataNDARequired}
                    />
                  }
                  label="Is a NDA required?"
                />
              </Grid>
            </Grid>
            {sellerBusinessForm.values.isUnmaskDataNDARequired && (
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6} sm={3} md={3}>
                  <UploadDragDrop
                    size="small"
                    linkText={fileUploaded ? 'Replace' : 'Upload'}
                    text="NDA file"
                    onDrop={(acceptedFiles) => {
                      handleFileUpload(acceptedFiles)
                    }}
                    uploading={fileUploading}
                    uploadDisabled={fileUploading}
                    maxSize={MAX_SIZE_FILE}
                    onDropRejected={handleFileRejected}
                    accept={acceptUpload}
                  />
                  <Typography variant="caption" display="block" gutterBottom>
                    Maximum allowed file size is <strong>100MB</strong>
                  </Typography>
                </Grid>
                {sellerBusinessForm.values.isUnmaskDataNDARequired &&
                  sellerBusinessForm.values.unmaskDataNDABlobUri && (
                    <Grid item xs={12} lg={12}>
                      <Button
                        disableRipple={true}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          onDownload()
                        }}
                        size="small"
                      >
                        Download NDA
                      </Button>
                    </Grid>
                  )}
              </Grid>
            )}
          </Box>

          {!isUk && (
            <Box mb={12}>
              <Typography variant="h6">Scrub Preferences</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Scrub Review Threshold"
                    type="number"
                    disabled={!isInternal || !hasPermissionScrub}
                    name="scrubReviewThreshold"
                    inputProps={{
                      step: '.01',
                      max: '100',
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    onChange={sellerBusinessForm.handleChange}
                    error={!!sellerBusinessForm.errors.scrubReviewThreshold}
                    value={sellerBusinessForm.values.scrubReviewThreshold || ''}
                    helperText={
                      <>{sellerBusinessForm.errors.scrubReviewThreshold}</>
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        disabled={!isInternal || !hasPermissionScrub}
                        onChange={sellerBusinessForm.handleChange}
                        name="includeTLODismissals"
                        checked={sellerBusinessForm.values.includeTLODismissals}
                        value={sellerBusinessForm.values.includeTLODismissals}
                      />
                    }
                    label="Include scrub dismissals"
                  />
                </Grid>

                {!isUk && (
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          disabled={!isInternal || !hasPermissionScrub}
                          onChange={sellerBusinessForm.handleChange}
                          name="holdScrub"
                          checked={sellerBusinessForm.values.holdScrub}
                          value={sellerBusinessForm.values.holdScrub}
                        />
                      }
                      label="Manual second scrub"
                    />
                  </Grid>
                )}

                {!isUk && (
                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label="Military Certificates"
                      name="militaryCertificates"
                      disabled={!isInternal || !hasPermissionScrub}
                      select
                      onChange={sellerBusinessForm.handleChange}
                      error={!!sellerBusinessForm.errors.militaryCertificates}
                      value={sellerBusinessForm.values.militaryCertificates}
                      helperText={
                        <>{sellerBusinessForm.errors.militaryCertificates}</>
                      }
                    >
                      {hardCodeData.getMilitaryCertificates().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                <Grid item xs={12} lg={8}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        disabled={!isInternal || !hasPermissionScrub}
                        onChange={sellerBusinessForm.handleChange}
                        name="militaryScrub"
                        value={sellerBusinessForm.values.militaryScrub}
                        checked={sellerBusinessForm.values.militaryScrub}
                        title="Consumer accounts will be automatically removed from portfolio on positive military scrubbing results"
                      />
                    }
                    label="Military Scrub"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Box mb={12}>
            <Typography variant="h6">BCO</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!isInternal || !hasCompliancePermission}
                      color="primary"
                      onChange={sellerBusinessForm.handleChange}
                      name="enableBCOReport"
                      value={sellerBusinessForm.values.enableBCOReport}
                      checked={sellerBusinessForm.values.enableBCOReport}
                    />
                  }
                  label="Enable BCO Report"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!isInternal || !hasCompliancePermission}
                      color="primary"
                      onChange={sellerBusinessForm.handleChange}
                      name="requireBCOFinancials"
                      checked={sellerBusinessForm.values.requireBCOFinancials}
                      value={sellerBusinessForm.values.requireBCOFinancials}
                    />
                  }
                  label="Financial Statements Required"
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={12}>
            <Typography variant="h6">
              Projected Monthly Placement Volume
            </Typography>
            <Grid container>
              <Grid item xs={4} lg={4}>
                <TextField
                  fullWidth
                  label="Projected Monthly Placement Volumes (est)"
                  type="number"
                  name="projectedMonthlyPlacementVolumes"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  error={
                    !!sellerBusinessForm.errors.projectedMonthlyPlacementVolumes
                  }
                  value={
                    sellerBusinessForm.values
                      .projectedMonthlyPlacementVolumes || ''
                  }
                  helperText={
                    <>
                      {
                        sellerBusinessForm.errors
                          .projectedMonthlyPlacementVolumes
                      }
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={12}>
            <Typography variant="h6">BOS Signer (E-Signature)</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Signer Name"
                  name="bosSignerName"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  error={!!sellerBusinessForm.errors.bosSignerName}
                  value={sellerBusinessForm.values.bosSignerName || ''}
                  helperText={<>{sellerBusinessForm.errors.bosSignerName}</>}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Signer Title"
                  name="bosSignerTitle"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  error={!!sellerBusinessForm.errors.bosSignerTitle}
                  value={sellerBusinessForm.values.bosSignerTitle || ''}
                  helperText={<>{sellerBusinessForm.errors.bosSignerTitle}</>}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Signer E-mail"
                  name="bosSignerEmail"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  error={!!sellerBusinessForm.errors.bosSignerEmail}
                  value={sellerBusinessForm.values.bosSignerEmail || ''}
                  helperText={<>{sellerBusinessForm.errors.bosSignerEmail}</>}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={12}>
            <Typography variant="h6">PSA Signer (E-Signature)</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Signer Name"
                  name="psaSignerName"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  error={!!sellerBusinessForm.errors.psaSignerName}
                  value={sellerBusinessForm.values.psaSignerName || ''}
                  helperText={<>{sellerBusinessForm.errors.psaSignerName}</>}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Signer Title"
                  name="psaSignerTitle"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  error={!!sellerBusinessForm.errors.psaSignerTitle}
                  value={sellerBusinessForm.values.psaSignerTitle || ''}
                  helperText={<>{sellerBusinessForm.errors.psaSignerTitle}</>}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Signer E-mail"
                  name="psaSignerEmail"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  error={!!sellerBusinessForm.errors.psaSignerEmail}
                  value={sellerBusinessForm.values.psaSignerEmail || ''}
                  helperText={<>{sellerBusinessForm.errors.psaSignerEmail}</>}
                />
              </Grid>
            </Grid>
          </Box>
          {isInternal && (
            <Box mb={12}>
              <Typography variant="h6">API</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="API Key"
                    name="ApiKey"
                    disabled={true}
                    value={newApiKey || sellerBusinessForm.values.apiKey || ''}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Button
                    startIcon={renewLoading && <CircularProgress size={16} />}
                    style={{ marginTop: '10px' }}
                    variant="outlined"
                    color="primary"
                    onClick={handleRenewApiKey}
                  >
                    Renew
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          {isInternal && (
            <Box mb={12}>
              <Typography variant="h6">Webhook Notification</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        disabled={!isInternal || !hasBasicInfoPermission}
                        onChange={sellerBusinessForm.handleChange}
                        name="enableWebhookPlacement"
                        value={sellerBusinessForm.values.enableWebhookPlacement}
                        checked={
                          sellerBusinessForm.values.enableWebhookPlacement
                        }
                        title="Enable placement notification"
                      />
                    }
                    label="Enable placement notification"
                  />
                </Grid>
                {sellerBusinessForm.values.enableWebhookPlacement && (
                  <Grid item xs={12} lg={8}>
                    <TextField
                      fullWidth
                      label="Url placement notification"
                      name="UrlPlacementNotification"
                      value={newApiKey || sellerBusinessForm.values.apiKey}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </>
      )}
      <Box mb={12}>
        <Typography variant="h6">Consumer Payment Information</Typography>
        <Grid container spacing={8}>
          <Grid item xs={8} lg={8}>
            <TextField
              fullWidth
              multiline
              label="Consumer Payment Instruction"
              name="consumerPaymentInstruction"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.consumerPaymentInstruction}
              value={sellerBusinessForm.values.consumerPaymentInstruction || ''}
              helperText={
                <>
                  Example: Call this number to make a payment (888)-444-2222 x
                  1234
                </>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Media File Name Convention</Typography>
        <Grid container spacing={8}>
          <Grid item xs={8} lg={8}>
            <TextField
              fullWidth
              multiline
              label="Media File Naming Convention"
              name="mediaFileNamingConvention"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.mediaFileNamingConvention}
              value={sellerBusinessForm.values.mediaFileNamingConvention}
              helperText={
                <>
                  [Account ID]_[Document Type] - 12345_LoanAgreement
                  <br />
                  [Account ID];[Document Type] - 12345;LoanAgreement
                  <br />
                  [Account ID] [Document Type] - 12345 LoanAgreement
                  <br />
                  [Account ID]#[Document Type] - 12345#LoanAgreement
                </>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Grid container spacing={12}>
          <Grid item xs={3} lg={3}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={sellerBusinessForm.handleChange}
                  name="mediaProvidedInBulk"
                  checked={sellerBusinessForm.values.mediaProvidedInBulk}
                  value={sellerBusinessForm.values.mediaProvidedInBulk}
                />
              }
              label="Media provided in Bulk"
            />
          </Grid>

          <Grid item xs={6} lg={6}>
            <TextField
              fullWidth
              label="Number of Days to Provide Media"
              type="number"
              disabled={
                !isInternal ||
                !hasBasicInfoPermission ||
                !sellerBusinessForm.values.mediaProvidedInBulk
              }
              name="numberOfDaysToProvideMedia"
              inputProps={{
                step: '1',
                max: '100',
                min: '0',
              }}
              onChange={sellerBusinessForm.handleChange}
              error={!!sellerBusinessForm.errors.numberOfDaysToProvideMedia}
              value={
                sellerBusinessForm.values.numberOfDaysToProvideMedia || null
              }
            />
          </Grid>
        </Grid>
      </Box>
      {isInternal && (
        <Box mb={12}>
          <Typography variant="h6">Process Portfolio Function</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={!isInternal || !hasBasicInfoPermission}
                    onChange={sellerBusinessForm.handleChange}
                    name="useAccountsProcessPortfolioFunc"
                    value={
                      sellerBusinessForm.values.useAccountsProcessPortfolioFunc
                    }
                    checked={
                      sellerBusinessForm.values.useAccountsProcessPortfolioFunc
                    }
                    title="Use Account's Process Portfolio Function"
                  />
                }
                label="Use Account's Process Portfolio Function"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {isInternal && hasBasicInfoPermission && (
        <Box display="flex" justifyContent="flex-end" my={2}>
          <Button
            startIcon={
              !addOrUpdateloading ? <Save /> : <CircularProgress size={16} />
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={addOrUpdateloading}
          >
            Save
          </Button>
        </Box>
      )}
      <ModalDialog
        header="Name History"
        isOpen={openNameHistory}
        onClose={handleNameHistoryToogle}
        buttonCancelText="Close"
        hideOkButton={true}
        width="600px"
      >
        {sellerBusinessId && (
          <BusinessNameHistory
            businessId={sellerBusinessId}
            refetchQueries={['GetSeller']}
          />
        )}
      </ModalDialog>
    </form>
  )
}

export default BusinessInformation
