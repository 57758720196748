import {
  AssetType,
  BuyerRelated,
  Department,
} from 'src/graphql/models/businessRecovery'
import { httpClient } from '../../axios-wrapper/httpClient'
import {
  ArchiveAgreementTemplate,
  GetAssetTypes,
  GetBusinessLicenses,
  GetBuyersRelatedToSeller,
  GetDepartments,
  SaveAgreementTemplate,
  UpdateAgreementTemplate,
  GetClientAssociations,
} from '../../urls/client'

export const getAssetTypes = async (): Promise<AssetType[]> => {
  const response = await httpClient.get(`${GetAssetTypes}`)

  return response.data
}

export const getDepartments = async (): Promise<Department[]> => {
  const response = await httpClient.get(`${GetDepartments}`)

  return response.data
}

export const getBuyerRelated = async (
  sellerId: string
): Promise<BuyerRelated[]> => {
  const response = await httpClient.get(GetBuyersRelatedToSeller, {
    params: { sellerId },
  })

  return response.data
}

export const saveAgreementTemplate = async (
  sellerId: string,
  buyerIds: string[],
  type: string,
  name: string,
  assetTypes: string[],
  file: any,
  allowedForForwardFlow: boolean,
  useAsCustomPSA: boolean
) => {
  const form = new FormData()
  form.append('name', name)
  form.append('type', type)
  form.append('sellerId', sellerId)
  form.append('buyerIds', JSON.stringify(buyerIds))
  form.append('assetTypes', JSON.stringify(assetTypes))
  form.append('file', file)
  form.append('allowedForForwardFlow', JSON.stringify(allowedForForwardFlow))
  form.append('useAsCustomPSA', JSON.stringify(useAsCustomPSA))
  const response = await httpClient.post(SaveAgreementTemplate, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const updateAgreementTemplate = async (id: number, name: string) => {
  const form = new FormData()
  form.append('name', name)
  form.append('id', id.toString())
  const response = await httpClient.post(UpdateAgreementTemplate, form)
  return response?.data
}
export const archiveAgreementTemplate = async (id: number, reason: string) => {
  const response = await httpClient.post(ArchiveAgreementTemplate, {
    id,
    reason,
  })
  return response?.data
}

export const getBusinessLicenses = async (
  businessId: string,
  kendoPagination: any
): Promise<any> => {
  const response = await httpClient.get(GetBusinessLicenses, {
    params: {
      businessId,
      kendoPagination: JSON.stringify(kendoPagination),
    },
  })
  return response?.data
}

export const getClientAssociations = async (
  clientId: number | undefined,
  onlyBuyersSellers: boolean | undefined
): Promise<any> => {
  const response = await httpClient.get(GetClientAssociations, {
    params: {
      clientId,
      onlyBuyersSellers,
    },
  })
  return response?.data
}
