import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
} from '@mui/material'
import { Menu, MenuItem } from '@progress/kendo-react-layout'
import { Link, useHistory } from 'react-router-dom'
import { Add, MoreVert } from '@mui/icons-material'
import React, { useContext, useEffect, useState } from 'react'
import { UserListResponse } from 'src/graphql/models/clientProfiles'
import { GET_USERS } from 'src/graphql/operations/queries/clientProfiles'
import { USER_MANAGE, USER_ROLES } from 'src/routes'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess, TENANT_TYPE, USER_ID } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { State } from '@progress/kendo-data-query'

import {
  Grid as KendoGrid,
  GridCellProps,
  GridColumn as KendoGridColumn,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid'
import { Align, Popup } from '@progress/kendo-react-popup'
import { formatDateAndTime } from 'src/utils/date'

interface UserListProps {
  tenantId: string
  clientId: number
  isInternalUsers?: boolean
  internalUserGrid?: boolean
}

const UserList = ({
  tenantId,
  clientId,
  isInternalUsers,
  internalUserGrid,
}: UserListProps) => {
  const ability = useContext(AbilityContext)

  const { user } = useContext(AuthContext)

  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'

  const [canEdit, setCanEdit] = useState(false)
  const [canSetPermission, setCanSetPermission] = useState(false)
  const [enabledUsers, setEnabledUsers] = useState(true)

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const {
    data: getUsersData,
    loading: usersLoading,
    fetchMore,
  } = useQuery<UserListResponse>(GET_USERS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,

    variables: {
      userListRequest: {
        tenantIdFilter: tenantId,
        idCurrentUser: isInternal ? '' : user.profile[USER_ID],
        enabledOnly: enabledUsers,
      },
      kendoPagination: JSON.stringify(gridState),
    },
  })
  const history = useHistory()
  useEffect(() => {
    setGridState({ skip: 0, take: 25, filter: undefined, sort: undefined })
  }, [setGridState])

  const ColumnMenu = (props: any): React.ReactElement => {
    return (
      <Box>
        <GridColumnMenuFilter {...props} expanded={true} />
      </Box>
    )
  }

  useEffect(() => {
    if (
      (internalUserGrid &&
        ability.can(PermissionCodeAccess.MANAGE_INTERNAL_PERMISSION, 'any')) ||
      (!internalUserGrid &&
        ability.can(PermissionCodeAccess.MANAGE_EXTERNAL_PERMISSION, 'any'))
    ) {
      setCanSetPermission(true)
    }

    if (
      (isInternalUsers &&
        ability.can(
          PermissionCodeAccess.CLIENT_INTERNAL_USER_PERMISSION,
          'any'
        )) ||
      (!isInternalUsers &&
        ability.can(
          PermissionCodeAccess.CLIENT_EXTERNAL_USER_PERMISSION,
          'any'
        ))
    ) {
      setCanEdit(true)
    }
  }, [isInternalUsers, ability, internalUserGrid])

  const [offSet, setOffSet] = useState({
    left: 0,
    top: 0,
  })

  const [anchorAlign] = useState<Align>({
    horizontal: 'right',
    vertical: 'bottom',
  })

  const [popupAlign] = useState<Align>({
    horizontal: 'left',
    vertical: 'center',
  })
  const [currentUserId, setCurrentUserId] = useState('')
  const [show, setShow] = useState<boolean>(false)
  const handleContextMenu = (e: any) => {
    setCurrentUserId(e.currentTarget.attributes['itemid'].value)

    setOffSet({
      left: e.clientX,
      top: e.clientY,
    })

    setShow(true)
  }

  useEffect(() => {
    if (show) {
      document.addEventListener(
        'click',
        (e) => {
          if (
            document.activeElement?.attributes.getNamedItem('role')?.value !==
              'menuitem' &&
            document.activeElement?.attributes.getNamedItem('type')?.value !==
              'button'
          ) {
            setShow(false)
          }
        },
        true
      )
    }

    if (!show) {
      document.removeEventListener(
        'click',
        () => {
          setShow(false)
        },
        true
      )
    }
  }, [show])

  return (
    <Box>
      <Box mb={4} mt={2} display="flex" justifyContent="space-between">
        <FormControlLabel
          control={
            <Switch
              color="primary"
              onChange={() => setEnabledUsers(!enabledUsers)}
              name="enabledUsers"
              checked={enabledUsers}
              value={enabledUsers}
            />
          }
          label="Enabled Users"
        />
        <Button
          disabled={!canEdit}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          component={Link}
          to={`${USER_MANAGE}/${tenantId}/${clientId}/`}
        >
          Add User
        </Button>
      </Box>
      <Box>
        <KendoGrid
          sortable
          style={{
            height: '100%',
            maxHeight: '80vh',
          }}
          pageable={{ pageSizes: [25, 50, 100] }}
          skip={gridState.skip}
          take={gridState.take}
          pageSize={gridState.take}
          filter={gridState.filter}
          sort={gridState.sort}
          data={getUsersData ? getUsersData?.userListResponse.userResponse : []}
          total={getUsersData ? getUsersData?.userListResponse.totalCount : 0}
          onDataStateChange={(e) => {
            setGridState(e.dataState)
            fetchMore({
              variables: {
                kendoPagination: JSON.stringify(e.dataState),
              },
            })
          }}
        >
          <KendoGridColumn
            field="lastName"
            title="Last Name"
            columnMenu={ColumnMenu}
          />
          <KendoGridColumn
            field="firstName"
            title="First Name"
            columnMenu={ColumnMenu}
          />
          <KendoGridColumn
            field="status"
            title="Status"
            columnMenu={ColumnMenu}
          />
          <KendoGridColumn
            field="displayName"
            title="Display Name"
            columnMenu={ColumnMenu}
          />
          <KendoGridColumn
            field="title"
            title="Title"
            columnMenu={ColumnMenu}
          />
          <KendoGridColumn
            field="creationDate"
            title="Creation Date"
            columnMenu={ColumnMenu}
            cell={(props: GridCellProps) => {
              return (
                <td>
                  {props.dataItem['creationDate'] == null
                    ? 'N/A'
                    : formatDateAndTime(props.dataItem['creationDate'])}
                </td>
              )
            }}
          />

          <KendoGridColumn
            field="id"
            title="Actions"
            cell={(props: GridCellProps) => {
              return (
                <td>
                  <IconButton
                    id={`${props.dataItem['id']}_action`}
                    itemID={props.dataItem['id']}
                    aria-label="more"
                    aria-controls="action-menus"
                    aria-haspopup="true"
                    size="small"
                    onClick={(e) => {
                      handleContextMenu(e)
                    }}
                  >
                    <MoreVert />
                  </IconButton>

                  <Popup
                    show={show}
                    offset={offSet}
                    popupAlign={popupAlign}
                    anchorAlign={anchorAlign}
                    animate={false}
                    className="wrapper-content"
                    popupClass="popup-content"
                  >
                    <Menu
                      vertical={true}
                      style={{ display: 'inline-block' }}
                      onSelect={(e) => {
                        if (e.item.text === 'Edit') {
                          history.push(
                            `${USER_MANAGE}/${tenantId}/${clientId}/${currentUserId}`
                          )
                        } else {
                          internalUserGrid
                            ? history.push(
                                `${USER_ROLES}/${currentUserId}/permission/internal/${tenantId}`
                              )
                            : history.push(
                                `${USER_ROLES}/${currentUserId}/permission/${tenantId}`
                              )
                        }
                      }}
                    >
                      <MenuItem
                        disabled={usersLoading || !canEdit}
                        text="Edit"
                        data={{
                          route: `${USER_MANAGE}/}`,
                        }}
                      />
                      <MenuItem
                        disabled={usersLoading || !canSetPermission}
                        text=" Manage Permission"
                        data={{
                          route: `${USER_ROLES}/`,
                        }}
                      />
                    </Menu>
                  </Popup>
                </td>
              )
            }}
          />
        </KendoGrid>
      </Box>
    </Box>
  )
}
UserList.defaultProps = {
  isInternalUsers: false,
}

export default UserList
