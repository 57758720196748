/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormHelperText,
  Autocomplete,
  Skeleton,
  FormControlLabel,
  Switch,
} from '@mui/material'

import { Save, List as ListIcon } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { BusinessAgencyRequestType } from 'src/graphql/models/agency'
import hardCodeData from 'src/utils/hardcodeData'
import {
  getCountryCurrency,
  getStateDropDownName,
  mapPropNullToString,
} from 'src/utils/common'
import { useHistory } from 'react-router-dom'
import { AGENCY_BUSINESS_MANAGE } from 'src/routes'
import { useSnackbar } from 'notistack'
import { BusinessNameHistory } from 'src/components/NameHistory'
import {
  CountryDataResponse,
  GetClient,
} from 'src/graphql/models/clientProfiles'
import {
  maskZipCode,
  maskPhoneNumber,
  maskMoney,
  unmaskPhoneNumber,
  unMaskMoney,
} from 'src/utils/masker'
import { useAddOrUpdateAgency } from 'src/graphql/operations/mutations/agency'
import { useQuery } from '@apollo/client'
import { GET_COUNTRY } from 'src/graphql/operations/queries/clientProfiles'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  AgencyType,
  PermissionCodeAccess,
  TENANT_TYPE,
  WEEKDAYS,
} from 'src/utils/constants'
import { format, parse } from 'date-fns'
import BusinessInformationSkeleton from 'src/components/BusinessSkeleton'
import { AbilityContext } from 'src/context/Can'
import { getLocaleDateString } from 'src/utils/date'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { DatePicker, ModalDialog } from 'everchain-uilibrary'
import { MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { BusinessStatusInfo } from 'src/components/BusinessStatusInfo'

interface AgencyBusinessInformationProps {
  agencyBusinessId?: string | undefined
  clientId: number
  agencyData?: BusinessAgencyRequestType | undefined
  client?: GetClient
  agencyLoading?: boolean
}

const AgencyBusinessInformation = ({
  clientId,
  agencyBusinessId,
  agencyData,
  client,
  agencyLoading,
}: AgencyBusinessInformationProps) => {
  const { data: countryResponse, loading: loadingCountryData } =
    useQuery<CountryDataResponse>(GET_COUNTRY, {
      onCompleted: (data: CountryDataResponse) => {
        AgencyBusinessForm.setFieldValue(
          'countryCode',
          data.countryDataResponse[0].countryCode
        )
      },
    })

  const validate = (values: any) => {
    const errors: any = {}

    if (
      values.membershipEstablished &&
      !moment(
        values.membershipEstablished,
        formatInput.toUpperCase(),
        true
      ).isValid()
    ) {
      errors.membershipEstablished = 'Invalid date'
    }

    if (
      values.insurance_ExpirationDate &&
      !moment(
        values.insurance_ExpirationDate,
        formatInput.toUpperCase(),
        true
      ).isValid()
    ) {
      errors.insurance_ExpirationDate = 'Invalid date'
    }

    if (
      values.onSiteAudit &&
      !moment(values.onSiteAudit, formatInput.toUpperCase(), true).isValid()
    ) {
      errors.onSiteAudit = 'Invalid date'
    }

    return errors
  }

  const formatInput = getLocaleDateString()

  const profileClientCountry =
    agencyData?.countryCode ||
    client?.countryCode ||
    process.env.REACT_APP_COUNTRY

  const country =
    countryResponse && countryResponse?.countryDataResponse[0].countryCode

  const showCountryOption =
    countryResponse && countryResponse?.countryDataResponse.length > 1

  const countryCode = client?.countryCode || process.env.REACT_APP_COUNTRY

  const initialDaysOfOperation = [
    WEEKDAYS[0],
    WEEKDAYS[1],
    WEEKDAYS[2],
    WEEKDAYS[3],
    WEEKDAYS[4],
  ]

  const validateZipCode = (zipCodeValue: string) => {
    if (AgencyBusinessForm.values.countryCode?.toLowerCase() === 'us') {
      const regex = /^[0-9]{5}(?:-[0-9]{4})?$/
      return regex.test(zipCodeValue || '')
    }
    if (AgencyBusinessForm.values.countryCode?.toLowerCase() === 'uk') {
      const regex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
      return regex.test(zipCodeValue || '')
    }
    if (AgencyBusinessForm.values.countryCode?.toLowerCase() === 'ca') {
      const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/
      return regex.test(zipCodeValue || '')
    }
    return false
  }

  const AgencyBusinessSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    status: Yup.string().nullable().required('Required'),
    customerServiceEmail: Yup.string().nullable().email('Invalid email'),
    primaryContact_EMail: Yup.string().nullable().email('Invalid email'),
    startHoursOfOperation: Yup.string().nullable(),
    recertificationFrequency: Yup.number()
      .nullable()
      .min(1, 'Recertification frequency must be more than or equal to 1')
      .max(60, 'Recertification frequency must be less than or equal to 60'),
    endHoursOfOperation: Yup.string()
      .nullable()
      .test(
        'endLaterStart',
        'End time must be greater than start time',
        // eslint-disable-next-line func-names
        function (endHoursOfOperation) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.parent.startHoursOfOperation
            ? parse(endHoursOfOperation || '', 'h:mm a', new Date()) >
                // eslint-disable-next-line react/no-this-in-sfc
                parse(this.parent.startHoursOfOperation, 'h:mm a', new Date())
            : true
        }
      ),
    postalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
    billingPostalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
  })

  const initialValueData = {
    status: 'Application In Process',
    legalName: '',
    name: '',
    street1: client?.street1 ?? '',
    street2: client?.street2 ?? '',
    city: client?.city ?? '',
    stateCode: client?.stateCode ?? '',
    postalCode: client?.postalCode ?? '',
    countryCode: client?.countryCode ?? country,
    corpHQPhoneNumber: '',
    customerServicePhoneNumber: '',
    customerServiceEmail: '',
    primaryContact_LastName: '',
    primaryContact_FirstName: '',
    primaryContact_EMail: '',
    primaryContact_OfficePhone: '',
    primaryContact_MobilePhone: '',
    importFromId: 0,
    insurance_Name: '',
    insurance_PolicyType: '',
    insurance_CoverageAmount: 0,
    insurance_ExpirationDate: null,
    insurance_Comment: '',
    webSiteUrl: '',
    paymentSiteUrl: '',
    certifiedDateUTC: '',
    startHoursOfOperation: null,
    endHoursOfOperation: null,
    recertificationFrequency: null,
    membershipEstablished: null,
    onSiteAudit: null,
    billingStreet1: client?.street1 ?? '',
    billingStreet2: client?.street2 ?? '',
    billingCity: client?.city ?? '',
    billingStateCode: client?.stateCode ?? '',
    billingPostalCode: client?.postalCode ?? '',
    numberOfCollectors: 0,
    maxNumberOfAccounts: 0,
    consumerPaymentInstruction: '',
    companyWebsiteAddress: '',
    taxId: '',
    businessTypeLLC: '',
    stateOfIncorporation: '',
    dateOfEstablishment: null,
    type: null,
  }
  const ability = useContext(AbilityContext)
  const [initialValues, setInitialValues] = useState<any>(initialValueData)
  const [openNameHistory, setOpenNameHistory] = useState(false)
  const loading = false
  const { user, profileClient } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const history = useHistory()
  const [daysOfOperation, setDaysOfOperation] = useState(initialDaysOfOperation)
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )
  const hasStatusPermission = ability.can(
    PermissionCodeAccess.CLIENT_NONSELLER_STATUS_PERMISSION,
    'any'
  )
  const hasInsurancePermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_INSURANCE_PERMISSION,
    'any'
  )
  const { enqueueSnackbar } = useSnackbar()

  const handleAddOrUpdateAgencyCompleted = (data: any) => {
    if (data) {
      const text = agencyBusinessId ? 'Update' : 'Create'
      enqueueSnackbar(`${text} agency successful`, {
        variant: 'success',
      })
      if (data.addOrUpdateAgency && data.addOrUpdateAgency.id) {
        history.push(
          `${AGENCY_BUSINESS_MANAGE}/${clientId}/${data.addOrUpdateAgency.id}`
        )
      }
    }
  }

  const { addOrUpdateAgency, loading: addOrUpdateAgencyLoading } =
    useAddOrUpdateAgency(handleAddOrUpdateAgencyCompleted)

  const AgencyBusinessForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AgencyBusinessSchema,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const agencyRequest = {
        status: values.status || null,
        legalName: values.legalName || null,
        name: values.name || null,
        street1: values.street1 || null,
        street2: values.street2 || null,
        city: values.city || null,
        stateCode: values.stateCode || null,
        postalCode: values.postalCode || null,
        countryCode: values.countryCode || null,
        corpHQPhoneNumber: values.corpHQPhoneNumber
          ? unmaskPhoneNumber(values.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: values.customerServicePhoneNumber
          ? unmaskPhoneNumber(values.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: values.primaryContact_OfficePhone
          ? unmaskPhoneNumber(values.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: values.primaryContact_MobilePhone
          ? unmaskPhoneNumber(values.primaryContact_MobilePhone, countryCode)
          : null,
        customerServiceEmail: values.customerServiceEmail || null,
        primaryContact_LastName: values.primaryContact_LastName || null,
        primaryContact_FirstName: values.primaryContact_FirstName || null,
        primaryContact_EMail: values.primaryContact_EMail || null,
        importFromId: values.importFromId || null,
        insurance_Name: values.insurance_Name || null,
        insurance_PolicyType: values.insurance_PolicyType || null,
        insurance_CoverageAmount: values.insurance_CoverageAmount
          ? Number(unMaskMoney(values.insurance_CoverageAmount))
          : null,
        insurance_ExpirationDate: values.insurance_ExpirationDate
          ? new Date(values.insurance_ExpirationDate)
          : null,
        insurance_Comment: values.insurance_Comment || null,
        webSiteUrl: values.webSiteUrl || null,
        paymentSiteUrl: values.paymentSiteUrl || null,
        certifiedDateUTC: values.certifiedDateUTC || null,
        startHoursOfOperation: values?.startHoursOfOperation,
        endHoursOfOperation: values?.endHoursOfOperation,
        daysOfOperation: daysOfOperation.map((x) => x.value),
        recertificationFrequency: values.recertificationFrequency || null,
        membershipEstablished: values.membershipEstablished
          ? new Date(values.membershipEstablished)
          : null,
        onSiteAudit: values.onSiteAudit ? new Date(values.onSiteAudit) : null,
        billingStreet1: values.billingStreet1 || null,
        billingStreet2: values.billingStreet2 || null,
        billingCity: values.billingCity || null,
        billingStateCode: values.billingStateCode || null,
        billingPostalCode: values.billingPostalCode || null,
        numberOfCollectors: values.numberOfCollectors || null,
        maxNumberOfAccounts: values.maxNumberOfAccounts || null,
        consumerPaymentInstruction: values.consumerPaymentInstruction || null,
        enablePartnershipScoring: values.enablePartnershipScoring || false,
        dateOfEstablishment: values.dateOfEstablishment
          ? new Date(values.dateOfEstablishment)
          : null,
        companyWebsiteAddress: values.companyWebsiteAddress || null,
        businessTypeLLC: values.businessTypeLLC || null,
        taxId: values.taxId || null,
        stateOfIncorporation: values.stateOfIncorporation || null,
        type: values.type
          ? AgencyType.find((x) => x.key === values.type)?.value
          : null,
      }

      if (agencyBusinessId) {
        addOrUpdateAgency({
          variables: {
            agency: {
              id: agencyBusinessId,
              clientId: Number(clientId),
              ...agencyRequest,
            },
          },
        })
      } else {
        addOrUpdateAgency({
          variables: {
            agency: {
              clientId: Number(clientId),
              ...agencyRequest,
            },
          },
        })
      }
    },
  })

  const handleNameHistoryToogle = () => {
    setOpenNameHistory(!openNameHistory)
  }

  const handleCountryChange = (value: any, name: any) => {
    AgencyBusinessForm.setFieldValue('stateCode', null, true)
    AgencyBusinessForm.setFieldValue('billingStateCode', null, true)
    AgencyBusinessForm.setFieldValue('postalCode', '', true)
    AgencyBusinessForm.setFieldValue('billingPostalCode', '', true)
    AgencyBusinessForm.setFieldValue(name, value, true)
  }

  useEffect(() => {
    if (agencyData) {
      setInitialValues((prevState: BusinessAgencyRequestType) => ({
        ...prevState,
        ...mapPropNullToString(agencyData),
        corpHQPhoneNumber: agencyData.corpHQPhoneNumber
          ? maskPhoneNumber(agencyData.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: agencyData.customerServicePhoneNumber
          ? maskPhoneNumber(agencyData.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: agencyData.primaryContact_OfficePhone
          ? maskPhoneNumber(agencyData.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: agencyData.primaryContact_MobilePhone
          ? maskPhoneNumber(agencyData.primaryContact_MobilePhone, countryCode)
          : null,
        insurance_CoverageAmount: agencyData.insurance_CoverageAmount
          ? maskMoney(
              parseFloat(
                agencyData.insurance_CoverageAmount.toString()
              ).toFixed(2)
            )
          : null,
        onSiteAudit: agencyData.onSiteAudit
          ? new Date(agencyData.onSiteAudit)
          : null,
        insurance_ExpirationDate: agencyData.insurance_ExpirationDate
          ? new Date(agencyData.insurance_ExpirationDate)
          : null,
        membershipEstablished: agencyData.membershipEstablished
          ? new Date(agencyData.membershipEstablished)
          : null,
        dateOfEstablishment: agencyData.dateOfEstablishment
          ? new Date(agencyData.dateOfEstablishment)
          : null,
        type: AgencyType.find((x) => x.value === agencyData.type)?.key,
      }))
      setDaysOfOperation(
        agencyData.daysOfOperation.length > 0
          ? WEEKDAYS.filter(
              (x) => agencyData.daysOfOperation.indexOf(x.value) !== -1
            )
          : initialDaysOfOperation
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyData])

  const onChangeTime = (date: any, field: string) => {
    AgencyBusinessForm.setFieldValue(
      field,
      format(new Date(date), 'hh:mm a'),
      false
    )
  }

  const getStatusDropdownOptions = (): { label: string; value: string }[] => {
    return AgencyType.map((key, value) => ({
      label: key.key,
      value: value.toString(),
    }))
  }

  const agencyTypeDropdownOptions = getStatusDropdownOptions()

  const handleChangeType = (value: any, name: any) => {
    AgencyBusinessForm.setFieldValue(name, value, false)
  }

  if (agencyLoading) {
    return <BusinessInformationSkeleton />
  }

  return (
    <>
      <form onSubmit={AgencyBusinessForm.handleSubmit}>
        <Box mb={12}>
          <Typography variant="h6">Agency Business Info</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.name}
                value={AgencyBusinessForm.values.name || ''}
                helperText={<>{AgencyBusinessForm.errors.name}</>}
                disabled={!isInternal || !hasBasicInfoPermission}
                InputProps={{
                  endAdornment: agencyBusinessId && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleNameHistoryToogle()
                        }}
                      >
                        <ListIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4} display="flex" flexDirection="row">
              <TextField
                fullWidth
                label="Status"
                select
                name="status"
                disabled={!isInternal || !hasStatusPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.status}
                value={AgencyBusinessForm.values.status}
                helperText={<>{AgencyBusinessForm.errors.status}</>}
              >
                {hardCodeData
                  .getBusinessStatus(AgencyBusinessForm.values.businessType)
                  .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
              <BusinessStatusInfo
                businessType={AgencyBusinessForm.values.businessType}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Legal Name"
                name="legalName"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.legalName}
                value={AgencyBusinessForm.values.legalName || ''}
                helperText={<>{AgencyBusinessForm.errors.legalName}</>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-companyWebsiteAddress"
                label="Company Website Address"
                name="companyWebsiteAddress"
                onChange={AgencyBusinessForm.handleChange}
                value={AgencyBusinessForm.values.companyWebsiteAddress || ''}
                error={!!AgencyBusinessForm.errors.companyWebsiteAddress}
                helperText={
                  <>{AgencyBusinessForm.errors.companyWebsiteAddress}</>
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-stateofIncorporation"
                label="State of Incorporation"
                name="stateOfIncorporation"
                onChange={AgencyBusinessForm.handleChange}
                value={AgencyBusinessForm.values.stateOfIncorporation || ''}
                error={!!AgencyBusinessForm.errors.stateOfIncorporation}
                helperText={
                  <>{AgencyBusinessForm.errors.stateOfIncorporation}</>
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-taxID"
                label={'Tax ID #'}
                name="taxId"
                inputProps={{
                  maxLength: 20,
                }}
                onChange={AgencyBusinessForm.handleChange}
                value={AgencyBusinessForm.values.taxId || ''}
                error={!!AgencyBusinessForm.errors.taxId}
                helperText={<>{AgencyBusinessForm.errors.taxId}</>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-businessType"
                label={'Business Type (LLC)'}
                name="businessTypeLLC"
                onChange={AgencyBusinessForm.handleChange}
                value={AgencyBusinessForm.values.businessTypeLLC || ''}
                error={!!AgencyBusinessForm.errors.businessTypeLLC}
                helperText={<>{AgencyBusinessForm.errors.businessTypeLLC}</>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Date of Establishment"
                disabled={!isInternal || !hasBasicInfoPermission}
                name="dateOfEstablishment"
                onChange={(date) => {
                  AgencyBusinessForm.setFieldValue(
                    'dateOfEstablishment',
                    date,
                    false
                  )
                }}
                value={AgencyBusinessForm.values.dateOfEstablishment}
                errorMessage={AgencyBusinessForm.errors.dateOfEstablishment?.toString()}
                country={profileClient?.Country}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={!isInternal}
                    onChange={AgencyBusinessForm.handleChange}
                    name="enablePartnershipScoring"
                    value={AgencyBusinessForm.values.enablePartnershipScoring}
                    checked={AgencyBusinessForm.values.enablePartnershipScoring}
                  />
                }
                label="Enable partnership scoring"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label="Agency Type"
                  name="type"
                  select
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={({ target: { value, name } }) => {
                    handleChangeType(value, name)
                  }}
                  error={!!AgencyBusinessForm.errors.type}
                  value={AgencyBusinessForm.values.type || ''}
                  helperText={<>{AgencyBusinessForm.errors.type}</>}
                >
                  {agencyTypeDropdownOptions.map((option) => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Hours of Operation (PST)</Typography>
          <Grid
            container
            spacing={8}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item xs={3} lg={3}>
              <MobileTimePicker
                label="Start"
                disabled={!isInternal || !hasBasicInfoPermission}
                value={
                  AgencyBusinessForm.values.startHoursOfOperation
                    ? dayjs(
                        parse(
                          AgencyBusinessForm.values.startHoursOfOperation,
                          'h:mm a',
                          new Date()
                        )
                      )
                    : AgencyBusinessForm.values.startHoursOfOperation
                }
                onChange={(date) => onChangeTime(date, 'startHoursOfOperation')}
                ampm
                minutesStep={30}
                orientation="landscape"
              />
            </Grid>
            <Grid item xs={3} lg={3}>
              <MobileTimePicker
                label="End"
                disabled={!isInternal || !hasBasicInfoPermission}
                value={
                  AgencyBusinessForm.values.endHoursOfOperation
                    ? dayjs(
                        parse(
                          AgencyBusinessForm.values.endHoursOfOperation,
                          'h:mm a',
                          new Date()
                        )
                      )
                    : AgencyBusinessForm.values.endHoursOfOperation
                }
                onChange={(date) => onChangeTime(date, 'endHoursOfOperation')}
                ampm
                minutesStep={30}
                orientation="landscape"
              />
              {AgencyBusinessForm.errors.endHoursOfOperation && (
                <FormHelperText style={{ color: '#d32f2f' }}>
                  <>{AgencyBusinessForm.errors.endHoursOfOperation}</>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} lg={6}>
              <Autocomplete
                id="daysOfOperation"
                multiple
                options={WEEKDAYS}
                disabled={!isInternal || !hasBasicInfoPermission}
                getOptionLabel={(option) => option.day}
                value={daysOfOperation}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    disabled={!isInternal || !hasBasicInfoPermission}
                    name="daysOfOperation"
                    label="Days of the Week"
                    placeholder="Chose one or more days of the week"
                    error={!!AgencyBusinessForm.errors.daysOfOperation}
                  />
                )}
                onChange={(ev, values) => {
                  values && values.length > 0
                    ? setDaysOfOperation(values)
                    : enqueueSnackbar(
                        'You need to select at least one weekday',
                        {
                          variant: 'error',
                        }
                      )
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Address</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street"
                name="street1"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.street1}
                value={AgencyBusinessForm.values.street1 || ''}
                helperText={<>{AgencyBusinessForm.errors.street1}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street 2"
                name="street2"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.street2}
                value={AgencyBusinessForm.values.street2 || ''}
                helperText={<>{AgencyBusinessForm.errors.street2}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="City"
                name="city"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.city}
                value={AgencyBusinessForm.values.city || ''}
                helperText={<>{AgencyBusinessForm.errors.city}</>}
              />
            </Grid>
            {showCountryOption && (
              <Grid item xs={12} lg={4}>
                {loadingCountryData ? (
                  <Skeleton variant="rectangular" width="100%" />
                ) : (
                  <TextField
                    fullWidth
                    label="Country"
                    name="countryCode"
                    select
                    disabled={!isInternal || !hasBasicInfoPermission}
                    onChange={({ target: { value, name } }) => {
                      handleCountryChange(value, name)
                    }}
                    error={!!AgencyBusinessForm.errors.countryCode}
                    value={AgencyBusinessForm.values.countryCode}
                    helperText={<>{AgencyBusinessForm.errors.countryCode}</>}
                  >
                    {countryResponse?.countryDataResponse.map((option) => (
                      <MenuItem
                        key={option.countryCode}
                        value={option.countryCode}
                      >
                        {option.countryDescription}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
            )}
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label={getStateDropDownName(country)}
                  name="stateCode"
                  select
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={AgencyBusinessForm.handleChange}
                  error={!!AgencyBusinessForm.errors.stateCode}
                  value={AgencyBusinessForm.values.stateCode || ''}
                  helperText={<>{AgencyBusinessForm.errors.stateCode}</>}
                >
                  {countryResponse?.countryDataResponse
                    .find(
                      (x) =>
                        x.countryCode === AgencyBusinessForm.values.countryCode
                    )
                    ?.stateInfo.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.description}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Postal Code"
                name="postalCode"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={({ target: { value, name } }) => {
                  const masked = maskZipCode(
                    value,
                    AgencyBusinessForm.values.countryCode
                  )
                  AgencyBusinessForm.setFieldValue(name, masked, true)
                }}
                error={!!AgencyBusinessForm.errors.postalCode}
                value={AgencyBusinessForm.values.postalCode || ''}
                helperText={<>{AgencyBusinessForm.errors.postalCode}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Billing Address</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street"
                name="billingStreet1"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.billingStreet1}
                value={AgencyBusinessForm.values.billingStreet1 || ''}
                helperText={<>{AgencyBusinessForm.errors.billingStreet1}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street 2"
                name="billingStreet2"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.billingStreet2}
                value={AgencyBusinessForm.values.billingStreet2 || ''}
                helperText={<>{AgencyBusinessForm.errors.billingStreet2}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="City"
                name="billingCity"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.billingCity}
                value={AgencyBusinessForm.values.billingCity || ''}
                helperText={<>{AgencyBusinessForm.errors.billingCity}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label={getStateDropDownName(country)}
                  name="billingStateCode"
                  select
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={AgencyBusinessForm.handleChange}
                  error={!!AgencyBusinessForm.errors.billingStateCode}
                  value={AgencyBusinessForm.values.billingStateCode}
                  helperText={<>{AgencyBusinessForm.errors.billingStateCode}</>}
                >
                  {countryResponse?.countryDataResponse
                    .find(
                      (x) =>
                        x.countryCode === AgencyBusinessForm.values.countryCode
                    )
                    ?.stateInfo.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.description}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Postal Code"
                name="billingPostalCode"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={({ target: { value, name } }) => {
                  const masked = maskZipCode(
                    value,
                    AgencyBusinessForm.values.countryCode
                  )
                  AgencyBusinessForm.setFieldValue(name, masked, true)
                }}
                error={!!AgencyBusinessForm.errors.billingPostalCode}
                value={AgencyBusinessForm.values.billingPostalCode || ''}
                helperText={<>{AgencyBusinessForm.errors.billingPostalCode}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Buyer Contact Information</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="HQ Phone"
                name="corpHQPhoneNumber"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  AgencyBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!AgencyBusinessForm.errors.corpHQPhoneNumber}
                value={AgencyBusinessForm.values.corpHQPhoneNumber || ''}
                helperText={<>{AgencyBusinessForm.errors.corpHQPhoneNumber}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Customer Services Phone"
                name="customerServicePhoneNumber"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  AgencyBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!AgencyBusinessForm.errors.customerServicePhoneNumber}
                value={
                  AgencyBusinessForm.values.customerServicePhoneNumber || ''
                }
                helperText={
                  <>{AgencyBusinessForm.errors.customerServicePhoneNumber}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Customer Services E-mail"
                name="customerServiceEmail"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.customerServiceEmail}
                value={AgencyBusinessForm.values.customerServiceEmail || ''}
                helperText={
                  <>{AgencyBusinessForm.errors.customerServiceEmail}</>
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Primary Contact</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Last Name"
                name="primaryContact_LastName"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.primaryContact_LastName}
                value={AgencyBusinessForm.values.primaryContact_LastName || ''}
                helperText={
                  <>{AgencyBusinessForm.errors.primaryContact_LastName}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="First Name"
                name="primaryContact_FirstName"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.primaryContact_FirstName}
                value={AgencyBusinessForm.values.primaryContact_FirstName || ''}
                helperText={
                  <>{AgencyBusinessForm.errors.primaryContact_FirstName}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="E-mail"
                name="primaryContact_EMail"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.primaryContact_EMail}
                value={AgencyBusinessForm.values.primaryContact_EMail || ''}
                helperText={
                  <>{AgencyBusinessForm.errors.primaryContact_EMail}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Direct (Phone)"
                name="primaryContact_OfficePhone"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  AgencyBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!AgencyBusinessForm.errors.primaryContact_OfficePhone}
                value={
                  AgencyBusinessForm.values.primaryContact_OfficePhone || ''
                }
                helperText={
                  <>{AgencyBusinessForm.errors.primaryContact_OfficePhone}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Mobile"
                name="primaryContact_MobilePhone"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  AgencyBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!AgencyBusinessForm.errors.primaryContact_MobilePhone}
                value={
                  AgencyBusinessForm.values.primaryContact_MobilePhone || ''
                }
                helperText={
                  <>{AgencyBusinessForm.errors.primaryContact_MobilePhone}</>
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Insurance</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Name"
                name="insurance_Name"
                disabled={!isInternal || !hasInsurancePermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.insurance_Name}
                value={AgencyBusinessForm.values.insurance_Name || ''}
                helperText={<>{AgencyBusinessForm.errors.insurance_Name}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Policy Type"
                name="insurance_PolicyType"
                disabled={!isInternal || !hasInsurancePermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.insurance_PolicyType}
                value={AgencyBusinessForm.values.insurance_PolicyType || ''}
                helperText={
                  <>{AgencyBusinessForm.errors.insurance_PolicyType}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Coverage Amount"
                disabled={!isInternal || !hasInsurancePermission}
                name="insurance_CoverageAmount"
                onChange={({ target: { value, name } }) => {
                  const masked = maskMoney(value)
                  AgencyBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!AgencyBusinessForm.errors.insurance_CoverageAmount}
                value={AgencyBusinessForm.values.insurance_CoverageAmount}
                helperText={
                  <>{AgencyBusinessForm.errors.insurance_CoverageAmount}</>
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {' '}
                      {getCountryCurrency(profileClientCountry)}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DatePicker
                label="Expiration Date"
                disabled={!isInternal || !hasInsurancePermission}
                name="insurance_ExpirationDate"
                onChange={(date) => {
                  AgencyBusinessForm.setFieldValue(
                    'insurance_ExpirationDate',
                    date,
                    false
                  )
                }}
                value={AgencyBusinessForm.values.insurance_ExpirationDate}
                errorMessage={AgencyBusinessForm.errors.insurance_ExpirationDate?.toString()}
                country={profileClient?.Country}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Comment"
                name="insurance_Comment"
                disabled={!isInternal || !hasInsurancePermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.insurance_Comment}
                value={AgencyBusinessForm.values.insurance_Comment || ''}
                helperText={<>{AgencyBusinessForm.errors.insurance_Comment}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Compliance</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                type="number"
                label="Recertification Frequency (Months)"
                name="recertificationFrequency"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.recertificationFrequency}
                value={AgencyBusinessForm.values.recertificationFrequency || ''}
                helperText={
                  <>{AgencyBusinessForm.errors.recertificationFrequency}</>
                }
                inputProps={{
                  max: '999',
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DatePicker
                name="membershipEstablished"
                id="membershipEstablished"
                label="Membership Established"
                disabled={!isInternal || !hasBasicInfoPermission}
                minDate={
                  new Date(AgencyBusinessForm.values.membershipEstablished)
                }
                value={AgencyBusinessForm.values.membershipEstablished}
                errorMessage={AgencyBusinessForm.errors.membershipEstablished?.toString()}
                onChange={(date) => {
                  AgencyBusinessForm.setFieldValue(
                    'membershipEstablished',
                    date,
                    false
                  )
                }}
                country={profileClient?.Country}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DatePicker
                name="onSiteAudit"
                id="onSiteAudit"
                label="On Site Audit"
                disabled={!isInternal || !hasBasicInfoPermission}
                minDate={new Date(AgencyBusinessForm.values.onSiteAudit)}
                value={AgencyBusinessForm.values.onSiteAudit}
                errorMessage={AgencyBusinessForm.errors.onSiteAudit?.toString()}
                onChange={(date) => {
                  AgencyBusinessForm.setFieldValue('onSiteAudit', date, false)
                }}
                country={profileClient?.Country}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Recover Information</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                type="number"
                label="Number of Collectors"
                name="numberOfCollectors"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.numberOfCollectors}
                value={AgencyBusinessForm.values.numberOfCollectors || ''}
                helperText={<>{AgencyBusinessForm.errors.numberOfCollectors}</>}
                inputProps={{
                  min: '0',
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                type="number"
                label="Maximum Number of Accounts"
                name="maxNumberOfAccounts"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.maxNumberOfAccounts}
                value={AgencyBusinessForm.values.maxNumberOfAccounts || ''}
                helperText={
                  <>{AgencyBusinessForm.errors.maxNumberOfAccounts}</>
                }
                inputProps={{
                  min: '0',
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Consumer Payment Information</Typography>
          <Grid container spacing={8}>
            <Grid item xs={8} lg={8}>
              <TextField
                fullWidth
                multiline
                label="Consumer Payment Instruction"
                name="consumerPaymentInstruction"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={AgencyBusinessForm.handleChange}
                error={!!AgencyBusinessForm.errors.consumerPaymentInstruction}
                value={
                  AgencyBusinessForm.values.consumerPaymentInstruction || ''
                }
                helperText={
                  <>
                    Example: Call this number to make a payment (888)-444-2222 x
                    1234
                  </>
                }
              />
            </Grid>
          </Grid>
        </Box>
        {(hasInsurancePermission ||
          hasStatusPermission ||
          (isInternal && hasBasicInfoPermission)) && (
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Button
              startIcon={
                !addOrUpdateAgencyLoading ? (
                  <Save />
                ) : (
                  <CircularProgress size={16} />
                )
              }
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || addOrUpdateAgencyLoading}
            >
              Save
            </Button>
          </Box>
        )}
      </form>
      <ModalDialog
        header="Name History"
        isOpen={openNameHistory}
        onClose={handleNameHistoryToogle}
        buttonCancelText="Close"
        hideOkButton={true}
        width="600px"
      >
        {agencyBusinessId && (
          <BusinessNameHistory
            businessId={agencyBusinessId}
            refetchQueriesRest="getAgencyDetails"
          />
        )}
      </ModalDialog>
    </>
  )
}

export default AgencyBusinessInformation
